/* ===========================
Contact page
=========================== */
.lp-jewelry-guide--contact {
    a {
        font-weight: var(--font-weight-regular);
    }

    .cover-contact {
        display: flex;
    }

    .cover-contact__wrapper {
        display: inline-flex;
        flex-direction: column;
        flex: 1 1 50%;
        gap: var(--space-1);
    }

    .cover-contact__wrapper .h4 {
        margin-bottom: var(--space-0);
    }

    .o-page-section.o-page-section--opening-hours {
        padding-top: var(--space-0);
    }

    .o-page-section--opening-hours .o-page-section__content {
        order: 0;
    }

    .o-page-section--opening-hours .o-page-section__image {
        @include breakpoint(min-768) {
            flex: 0 0 65%;
            max-width: 65%;
        }
    }

    .o-page-section--opening-hours .o-page-section__image img {
        @include breakpoint(min-768) {
            max-width: rem(861);
        }
     }
   

    .contact-opening-hours {
        margin: var(--space-0);
        padding: var(--space-0);
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: var(--space-6);

        @include breakpoint(min-768) {
            gap: var(--space-0);
        }
    }

    .contact-opening-hours li {
        @include breakpoint(min-768) {
            display: flex;
        }
    }

    .contact-opening-hours li p {
        margin: var(--space-0);
        min-width: rem(160);
        font-size: var(--font-size-3);
        line-height: var( --line-height-2);

        @include breakpoint(min-768) {
            font-size: var(--font-size-4);
            line-height: var( --line-height-3);
        }
    }


    .contact-opening-hours li strong {
        font-size: var(--font-size-3);
        line-height: var( --line-height-2);

        @include breakpoint(min-768) {
            font-size: var(--font-size-4);
            line-height: var( --line-height-3);
        }
    }

    .contact-map-links {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: var(--space-6);
        font-size: var(--font-size-3);
        line-height: var( --line-height-2);

        @include breakpoint(min-768) {
            flex-direction: row;
            gap: var(--space-10);
            font-size: var(--font-size-4);
            line-height: var( --line-height-3);
        }
    }

    .contact-map-links a {
        display: inline-flex;
        align-items: center;
        gap: var(--space-2);
    }

    .contact-map-links a .icon {
        fill: var( --gray-40);
        width: var(--space-5);
        height: var(--space-5);
    }

    .o-page-section--form {
        justify-content: center;
    }

    .o-page-section--form .o-page-section__content {
        max-width: rem(640);
    }

    .o-page-section--form .o-page-section__content .o-page-section__heading-2 {
        text-align: center;
    }

}



// Contact form

.contact-form {
	display: flex;
	flex-wrap: wrap;
	// Negative margin for contained inputs
	margin: 0 calc(var(--space-2) * -1) var(--space-8) calc(var(--space-2) * -1);
	padding: 0;
	list-style-type: none;
}

.contact-form--delivery .contact-form__item:not(:last-child),
.contact-form--payment .contact-form__item:not(:last-child) {
	margin: 0 var(--space-2);
}

.contact-form__item--underline:not(:last-child) {
	border-bottom: var(--space-025) solid var(--gray-20);
}

.contact-form__item {
	margin-bottom: var(--space-4);
	display: flex;
	// Width minus margin
	flex: 0 0 calc(100% - (2 * var(--space-2)));
	margin: var(--space-2);
}

.contact-form__item[hidden] {
	display: none;
}

.contact-form__item--width-50 {
	@include breakpoint(min-960) {
		// Width minus margin
		flex: 0 0 calc(50% - (2 * var(--space-2)));
	}
}

.contact-form__item--width-25 {
	// Width minus margin
	flex: 0 0 calc(25% - (2 * var(--space-2)));
}

.contact-form__item--width-75 {
	// Width minus margin
	flex: 0 0 calc(75% - (2 * var(--space-2)));
}

.contact-form__item .c-input-label,
.contact-form__item .c-textarea-label {
	width: 100%;
}

.contact-form__item .c-radio-btn {
	padding: var(--space-4) 0;
}

.contact-form__btn {
	margin-bottom: var(--space-4);

	@include breakpoint(min-960) {
		position: static;
	}
}