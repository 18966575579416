/* ===========================
Homepage
=========================== */

@import '../components/category-section';
@import '../components/latest-jewelry-carousel';
@import '../components/advantages-section';


.hp-about {
	padding: rem(60) 0 rem(80) 0;
	background: $white;

	@include breakpoint(min-768) { padding: rem(64) 0 rem(80) 0; }
	@include breakpoint(min-1280) { padding: rem(80) 0 rem(100) 0; }
	@include breakpoint(min-1280) { padding: rem(100) 0 rem(160) 0; }
}

.hp-about h1 {
	color: $gray-50;
	font-size: var(--font-size-8);
	line-height: rem(32);
	font-family: $font-stack-display;
	text-align: center;
	font-weight: $font-regular;
	display: block;
	margin-bottom: rem(24);

	@include breakpoint(min-768) {
		font-size: var(--font-size-9);
		line-height: rem(42);
		max-width: percent(640, 768);
		text-align: left;
		margin: 0 auto rem(28) auto; 
	}

	@include breakpoint(min-960) {
		max-width: percent(640, 960);
	}

	@include breakpoint(min-1024) {
		width: percent(640, 1024);
	}

	@include breakpoint(min-1280) {
		text-align: left;
		width: percent(544, 1280);
		margin: 0 0 rem(28) 0;
	}
}

.hp-about p {
	font-size: var(--font-size-4); 
	line-height: rem(24);
	color: $gray-70;
	text-align: center; 
	margin-bottom: rem(28); 
	max-width: 94%;
	display: block;
	margin: 0 auto rem(28) auto;

	@include breakpoint(min-640) {
		max-width: 80%;
	}

	@include breakpoint(min-768) {
		max-width: percent(640, 768);
		text-align: left;
	}

	@include breakpoint(min-960) {
		max-width: percent(640, 960);
	}

	@include breakpoint(min-1024) {
		max-width: percent(640, 1024);
	}

	@include breakpoint(min-1280) {
		max-width: percent(544, 1280);
		margin: 0 0 rem(28) 0;
	}
}

.hp-about p:first-of-type {
	color: $gray-80;
	font-family: $font-stack-display;
	font-weight: $font-regular;
	letter-spacing: - rem(0.5);
	margin-bottom: rem(36);
	font-size: var(--font-size-5);
	line-height: rem(24);

	@include breakpoint(min-640) {
		font-size: var(--font-size-6);
		line-height: rem(28);
	}
}

.hp-about a {
	display: table;
	margin: 0 auto;
	color: $gray-90;
	box-shadow: 0 rem(1) 0 0 $gray-90;
	text-decoration: none;
	font-size: var(--font-size-3);
	line-height: rem(28);
	text-transform: uppercase;
	font-weight: $font-semibold;
	position: relative;
	transition: box-shadow .2s $ease-in-out-quad;

	@include breakpoint(min-768) {
		display: inline-block;
		margin-left: percent(64, 768);
	}

	@include breakpoint(min-960)    { margin-left: percent(160, 960); }
	@include breakpoint(min-1024)   { margin-left: percent(193, 1024); }
	@include breakpoint(min-1280)   { margin-left: 0; }
}

.hp-about a:hover {
	box-shadow: 0 rem(2) 0 0 $gray-90;
}

.hp-about a:hover .icon-arrow-right {
	transform: translateX(rem(6));
}

.hp-about a .icon-arrow-right {
	position: absolute;
	top: rem(8);
	right: - rem(16);
	display: block;
	width: rem(12);
	height: rem(12);
	transition: transform .2s $ease-in-out-quad;
}

.hp-about__image img {
	width: 100%;
	max-width: rem(344);
	height: auto;
	margin: 0 auto rem(40) auto;
	display: block;

	@include breakpoint(min-640) {
		max-width: rem(767);
	}

	@include breakpoint(min-1280) {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: percent(520, 1280);
		max-width: rem(520);
	}
}

// Marianne Days

.c-cover-image-fullscreen.marianne-days h1 {
	@include breakpoint(min-768) {
		color: var(--pink-90)
	}
}

.c-cover-image-fullscreen.marianne-days p {
    @include breakpoint(min-768) {
		color: var(--pink-70)
	}
}

.christmas-2022-cover .c-cover-image-fullscreen__heading,
.christmas-2022-cover .c-cover-image-fullscreen__desc {

	@include breakpoint(min-768) {
		color: var(--white);
	}
}