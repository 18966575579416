/* ===========================
Advantages section - component
=========================== */
.c-advantages-section {
    background-color: var(--gray-05);
    padding: var(--space-8) 0;

    @include breakpoint(min-768) {
        padding: var(--space-16) 0;
    }

    @include breakpoint(min-1280) {
        padding: var(--space-20) 0;
    }
}

//page-wrap
.c-advantages-section .page-wrap {
    width: 100%;

    @include breakpoint(min-768) {
        width: 96%;
    }
}

.c-advantages-section__content {
    text-align: center;
    margin: 0 auto var(--space-8) auto;
    max-width: rem(640);

    @include breakpoint(min-768) {
        margin: 0 auto var(--space-10) auto;
    }

    @include breakpoint(min-1280) {
        margin: 0 auto var(--space-20) auto;
    }
}

.c-advantages-section__heading {
    display: block;
    font-size: var( --font-size-7);
    line-height: var( --line-height-5);
   
    @include breakpoint(min-768) {
        font-size: var(--font-size-9);
        line-height: rem(52);
    }
}

.c-advantages-section__advantages-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: var(--space-8);

    @include breakpoint(min-768) {
        gap: var(--space-16);
    }

    @include breakpoint(min-1280) {
        gap: 0;
    }
}

.c-advantages-section__advantages-item {
    @include breakpoint(min-768) {
        min-height: rem(640);
        display: inline-flex;
        align-items: flex-end;
        justify-content: flex-start;
    }
}

.c-advantages-section__advantages-item .gia-logo {
    display: none;

    @include breakpoint(min-1280) {
        display: block;
        width: rem(440);
        position: absolute;
        transform: rotate(-90deg);
        opacity: .1;
        bottom: 50%;
        left: rem(-252);
    }
}

.c-advantages-section__advantages-item:first-child {
    @include breakpoint(min-768) {
        align-items: flex-start;
    }
}

.c-advantages-section__advantages-item:nth-child(even) {
    @include breakpoint(min-768) {
        justify-content: flex-end;
    }

    @include breakpoint(min-1280) {
        margin-top: calc(rem(232) * -1);
    }
}

.c-advantages-section__advantages-item:last-child {
    @include breakpoint(min-1280) {
        margin-top: calc(rem(24) * -1);
    }
}

.c-advantages-section__advantages-item:nth-child(even) picture {
    @include breakpoint(min-768) {
        left: auto;
        right: 0;
    }
}

.c-advantages-section__advantages-item:nth-child(even) .c-advantages-section__advantages-wrapper {
    @include breakpoint(min-768) {
        margin: var(--space-10) var(--space-40) var(--space-10) 0;
    }
}

.c-advantages-section__advantages-list picture {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(375);

    @include breakpoint(min-768) {
        width: rem(640);
        height: rem(640);
    }
}

.c-advantages-section__advantages-list img {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include breakpoint(min-480) {
        transform: translate(-50%, -40%);
    }

    @include breakpoint(min-768) {
        transform: translate(-50%, -50%);
    }
}

.c-advantages-section__advantages-wrapper {
    position: relative;
    background-color: var(--white);
    padding: var(--space-4);
    margin: rem(200) var(--space-4) 0 var(--space-4);

    @include breakpoint(min-480) {
        margin: rem(240) var(--space-4) 0 var(--space-4);
    }

    @include breakpoint(min-768) {
        padding: var(--space-8);
        max-width: rem(640);
        margin: var(--space-10) 0 var(--space-10) var(--space-40);
    }

    @include breakpoint(min-1280) {
        padding: var(--space-10);
    }

}

.c-advantages-section__advantages-heading {
    display: block;
    font-size: var( --font-size-6);
    line-height: rem(26);
    margin-bottom: var(--space-2);
   
    @include breakpoint(min-768) {
        font-size: var(--font-size-8);
        line-height: rem(42);
    }
}

.c-advantages-section__advantages-desc {
    display: block;
    color: var(--gray-70);
    font-size: var(--font-size-3);
    line-height: rem(22);
    margin: 0 0 var(--space-4) 0;

    @include breakpoint(min-768) {
        font-size: var(--font-size-4);
        line-height: var( --line-height-3);
        margin: 0 0 var(--space-6) 0;
    }
}

.c-advantages-section__advantages-desc:last-of-type {
    margin-bottom: var(--space-8);

    @include breakpoint(min-768) {
        margin-bottom: var(--space-10);
    }
}