/* ===========================
Affiliate landing page
=========================== */
.affiliate-landing-page {
    .affiliate-landing-cover {
        padding: rem(60) 0;

        .l-affiliate-section-wrapper {
            @include breakpoint(min-640) {
                flex-direction: column;
            }

            @include breakpoint(min-960) {
                flex-direction: row;
            }
        }

        @include breakpoint(min-1280) {
            padding: rem(120) 0 rem(80);
        }

        &__image {
            max-width: rem(344);
            width: 100%;
            order: 1;

            img {
                width: 100%;
            }

            @include breakpoint(min-640) {
                max-width: rem(480);
            }

            @include breakpoint(min-768) {
                max-width: rem(560);
            }

            @include breakpoint(min-960) {
                margin-left: rem(40);
                margin-bottom: 0;
                order: 0;
            }

            @include breakpoint(min-1280) {
                max-width: rem(660);
            }

            @include breakpoint(min-1600) {
                max-width: rem(794);
            }
        }

        &__text {
            text-align: center;
            max-width: rem(593);

            @include breakpoint(min-960) {
                text-align: left;
            }

            @include breakpoint(min-1280) {
                margin: 0 auto 0 0;
            }

            h1 {
                font-size: var(--font-size-9);
                line-height: rem(44);
                font-family: $font-stack-display;
                font-weight: $font-regular;
                margin: 0 auto rem(16) auto;

                @include breakpoint(min-768) {
                    font-size: var(--font-size-10);
                    line-height: rem(52);
                    margin: 0 auto rem(24) auto;
                }
                @include breakpoint(min-960) {
                    margin: 0 auto rem(24) 0;
                }
            }

            p {
                color: $gray-70;
                font-size: var(--font-size-4);
                line-height: rem(24);
                letter-spacing: -0.6px;
                margin: 0 auto rem(16) auto;
                padding: 0;

                @include breakpoint(min-768) {
                    font-size: var(--font-size-5);
                    line-height: rem(28);
                }

                &:first-of-type {
                    color: $gray-80;
                    font-family: $font-stack-display;
                    font-weight: $font-regular;

                    @include breakpoint(min-768) {
                        font-size: var(--font-size-7);
                        line-height: rem(32);
                        letter-spacing: -.8;
                        margin-bottom: rem(55);
                    }
                }

                &:last-of-type {
                    margin: 0 auto rem(24) auto;

                    @include breakpoint(min-640) {
                        margin: 0 0 rem(40) 0;
                    }
                }
            }

            strong {
                color: $gray-90;
                font-weight: $font-semibold;
                line-height: rem(24);
            }

            .buttons-wrapper {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin:  0 auto rem(40) auto;

                @include breakpoint(min-640) {
                    flex-direction: row;
                    justify-content: center;
                    margin:  0 auto rem(60) auto;
                }

                @include breakpoint(min-960) {
                    justify-content: left;
                    margin: 0;
                }
            }

            .btn {
                width: rem(200);

                @include breakpoint(min-960) {
                    width: rem(180);
                }

                @include breakpoint(min-1024) {
                    width: rem(200);
                }
            }

            .link-arrow {
                display: table;
                margin: rem(24) 0 0 0;
                color: $gray-90;
                box-shadow: 0 rem(1) 0 0 $gray-90;
                text-decoration: none;
                font-size: var(--font-size-3);
                line-height: rem(28);
                text-transform: uppercase;
                font-weight: $font-semibold;
                position: relative;
                transition: box-shadow .2s $ease-in-out-quad;

                &:hover {
                    box-shadow: 0 rem(2) 0 0 $gray-90;
                }

                &:hover .icon-arrow-right {
                    transform: translateX(rem(6));
                }

                @include breakpoint(min-640) {
                    display: inline-block;
                    margin: 0 0 0 percent(40, 768);
                    flex: 0 0 auto;
                }

                @include breakpoint(min-960)    { margin-left: percent(40, 960); }
                @include breakpoint(min-1024)   { margin-left: percent(60, 1024); }
                @include breakpoint(min-1280)   { margin-left: rem(52); }

            }

            .icon-arrow-right {
                position: absolute;
                top: rem(8);
                right: - rem(16);
                display: block;
                width: rem(12);
                height: rem(12);
                transition: transform .2s $ease-in-out-quad;
            }
        }
    }

    .affiliate-landing-advantages {
        padding: 0 0 rem(32) 0;

        @include breakpoint(min-640) {
            padding: rem(40) 0 rem(60);
        }

        @include breakpoint(min-1280) {
            padding: rem(80) 0 rem(160);
        }

        .l-affiliate-section-wrapper {
            @include breakpoint(min-960) {
                min-height: rem(507);
            }
        }

        &__image {
            display: none;
            width: 100%;
            position: absolute;

            @include breakpoint(min-640) {
                display: block;
                max-width: rem(839);
            }

            @include breakpoint(min-960) {
                width: percent(520,1024);
            }

            @include breakpoint(min-1280) {
                width: percent(720,1600);
            }

            @include breakpoint(min-1600) {
                width: 100%;
            }

            img {
                width: 100%;
            }
        }

        &__list {
            position: relative;
            z-index: $z-index-1;
            text-align: left;
            border: 2px solid $gray-20;
            background-color: $white;
            padding: rem(16) rem(24);
            max-width: rem(360);
            width: 100%;

            @include breakpoint(min-640) {
                margin: 0 auto;
                max-width: rem(540);
                padding: rem(24) rem(32);
            }

            @include breakpoint(min-960) {
                max-width: rem(620);
                order: 1;
                padding: rem(40) rem(48);
                margin: 0 rem(40) 0 auto;
            }

            @include breakpoint(min-1024) {
                max-width: rem(727);
            }

            @include breakpoint(min-1280) {
                max-width: rem(727);
                padding: rem(52) rem(60);
                margin: rem(48) rem(64) 0 auto;
            }

            h2 {
                margin: 0 auto rem(20) auto;
                font-family: $font-stack-display;
                font-weight: $font-regular;

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                counter-reset: adv-counter;

                li {
                    padding: 0 0 0 rem(24);
                    margin: 0 0 rem(16) 0;

                    &::before {
                        color: $gray-50;
                        position: absolute;
                        left: 0;
                        content: counter(adv-counter)'.';
                        counter-increment: adv-counter;
                    }
                }
            }
        }
    }

    .affiliate-landing-partnership-info {
        margin: rem(16) 0 0 0;
        padding: rem(32) 0;
        background-color: $gray-10;

        @include breakpoint(min-480) {
            margin: rem(24) 0 0 0;
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            margin: rem(32) 0 0 0;
            padding: rem(60) 0;
        }

        @include breakpoint(min-1280) {
            margin: rem(40) 0 0 0;
            padding: rem(80) 0;
        }

        &__text {
            text-align: center;
            max-width: rem(593);
            margin: 0 auto rem(40) auto;

            @include breakpoint(min-1280) {
                max-width: rem(640);
                margin: 0 auto rem(60) auto;
            }

            h2 {
                font-family: $font-stack-display;
                font-weight: $font-regular;
                margin-bottom: rem(16);

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                }

                @include breakpoint(min-1280) {
                    margin-bottom: rem(24);
                }

                small {
                    color: $gray-50;
                    font-family: $font-stack-text;
                    font-size: var(--font-size-3);
                    line-height: rem(14);
                    font-weight: $font-semibold;
                    text-transform: uppercase;
                    display: block;
                }
            }
        }

        .affiliate-info-blocks {
            margin: 0 auto;
            padding: 0;
            list-style-type: none;
            width: 100%;
            max-width: rem(929);

            &__item {
                background-color: $white;
                margin: 0 0 rem(8) 0;
                padding: rem(20) rem(24);

                @include breakpoint(min-768) {
                    padding: rem(38) rem(40);
                }

                &:last-of-type {
                    margin: 0;
                }

                h3 {
                    font-size: var(--font-size-6);
                    line-height: rem(28);
                    font-family: $font-stack-display;
                    font-weight: $font-regular;
                    margin-bottom: rem(16);

                    @include breakpoint(min-768) {
                        font-size: var(--font-size-7);
                        line-height: rem(32);
                        margin-bottom: rem(24);
                    }
                }

                p {
                    color: $gray-70;
                    font-size: var(--font-size-4);
                    line-height: rem(24);
                    letter-spacing: -0.6px;
                }

                ul {
                    margin: rem(24) 0;
                    padding: 0 0 0 rem(16);
                    list-style-type: none;
                    counter-reset: adv-counter;

                    li {
                        padding: 0 0 0 rem(24);
                        margin: 0 0 rem(16) 0;

                        &:last-child {
                            margin: 0;
                        }

                        &::before {
                            text-align: left;
                            color: $gray-50;
                            font-size: var(--font-size-4);
                            position: absolute;
                            left: 0;
                            content: counter(adv-counter)'.';
                            counter-increment: adv-counter;
                        }
                    }
                }

                .btn {
                    margin: rem(40) 0 0 0;
                    max-width: rem(200);
                }
            }
        }

    }

    //Common wrapper for section
    .l-affiliate-section-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include breakpoint(min-640) {
            flex-direction: row;
        }
    }
}

/* ===========================
Affiliate instructions page
=========================== */
.affiliate-instructions-page {
    .affiliate-instructions-cover {
        text-align: center;
        padding: rem(60) 0;

        @include breakpoint(min-1280) {
            padding: rem(80) 0;
        }

        h1 {
            font-family: $font-stack-display;
            font-weight: $font-regular;
            margin: 0 auto rem(16) auto;

            @include breakpoint(min-1280) {
                max-width: rem(720);
                font-size: var(--font-size-10);
                line-height: rem(48);
                margin-bottom: rem(24);
            }
        }

        p {
            color: $gray-70;
            margin: 0 auto;
            letter-spacing: -0.6px;
            max-width: rem(660);

            @include breakpoint(min-1280) {
                max-width: rem(720);
                font-size: var(--font-size-5);
                line-height: rem(28);
            }
        }
    }

    .affiliate-instructions-info {
        margin: rem(16) 0 0 0;
        padding: rem(32) 0;
        background-color: $gray-10;

        >.page-wrap {
            display: flex;
        }

        @include breakpoint(min-480) {
            margin: rem(24) 0 0 0;
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            margin: rem(32) 0 0 0;
            padding: rem(60) 0;
        }

        @include breakpoint(min-1280) {
            margin: rem(40) 0 0 0;
            padding: rem(80) 0;
        }

        //Left menu
        .affiliate-instructions-aside-menu-container {
            margin-bottom: rem(40);
            flex: 0 0 auto;
            display: none;

            @include breakpoint(min-960) {
                display: block;
            }

            @include breakpoint(min-1280) {
                margin-bottom: 0;
                width: rem(260);
            }

            &__block-heading {
                display: block;
                color: $gray-90;
                font-size: var(--font-size-5);
                font-weight: $font-semibold;
                text-transform: uppercase;
                margin-bottom: rem(18);
            }

            &__block-list {
                margin: 0;
                padding: 0 0 0 rem(20);
                list-style-type: none;

                li {
                    padding-left: rem(24);
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(12);

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        background-color: $gray-40;
                        width: rem(8);
                        height: rem(2);
                        transform: translateY(-50%);
                    }
                }

                a {
                    color: $gray-90;
                    text-decoration: none;
                    font-size: var(--font-size-4);
                    line-height: rem(24);
                    position: relative;
                    transition: box-shadow .2s $ease-in-out-quad;

                    &:hover {
                        box-shadow: 0 rem(2) 0 0 $gray-90;
                    }

                    &:hover .icon-arrow-right {
                        opacity: 1;
                    }
                }

                .icon-arrow-right {
                    position: absolute;
                    top: rem(10);
                    right: - rem(24);
                    display: block;
                    width: rem(12);
                    height: rem(12);
                    opacity: 0;
                    transition: opacity .2s $ease-in-out-quad;
                }
            }
        }

        .l-affiliate-instructions-aside-block-wrapper {
            margin: 0 0 rem(36) 0;

            &:last-of-type {
                margin: 0;
            }
        }

        .affiliate-info-blocks {
            margin: 0 auto;
            padding: 0;
            list-style-type: none;
            width: 100%;
            max-width: rem(929);

            @include breakpoint(min-960) {
                margin: 0 0 0 percent(76,1600);
            }

            @include breakpoint(min-1600) {
                margin: 0 percent(335,1600) 0 percent(76,1600);
            }

            &__item {
                background-color: $white;
                margin: 0 0 rem(8) 0;
                padding: rem(20) rem(24);

                @include breakpoint(min-768) {
                    padding: rem(38) rem(40);
                }

                &:last-of-type {
                    margin: 0;
                }

                h3 {
                    font-size: var(--font-size-6);
                    line-height: rem(28);
                    font-family: $font-stack-display;
                    font-weight: $font-regular;
                    margin-bottom: rem(16);

                    @include breakpoint(min-768) {
                        font-size: var(--font-size-7);
                        line-height: rem(32);
                        margin-bottom: rem(24);
                    }
                }

                p {
                    color: $gray-70;
                    font-size: var(--font-size-5);
                    line-height: rem(28);
                    letter-spacing: -0.6px;

                    strong {
                        color: $gray-90;
                    }
                }

                picture {
                    display: block;
                    width: 100%;
                    max-width: rem(400);
                    margin: rem(16) auto 0 auto;

                    @include breakpoint(min-640) {
                        margin: rem(24) auto 0 auto;
                        max-width: rem(849);
                    }

                    img {
                        width: 100%;
                    }
                }


                .btn {
                    margin: rem(40) 0 0 0;
                    max-width: rem(200);
                }
            }
        }

        .affiliate-info-sub-blocks {
            margin: 0;
            padding: 0;
            list-style-type: none;

            &__item {
                margin: rem(40) 0;

                &:last-of-type {
                    margin: rem(40) 0 0 0;
                }

                h4 {
                    font-size: var(--font-size-4);
                    line-height: rem(20);
                    text-transform: uppercase;
                    font-weight: $font-semibold;
                    margin-bottom: rem(4);

                    @include breakpoint(min-768) {
                        font-size: var(--font-size-5);
                        line-height: rem(24);
                    }
                }

                p {
                    color: $gray-70;
                    font-size: var(--font-size-4);
                    line-height: rem(24);
                    letter-spacing: -0.5px;
                    margin: 0 0 rem(16) 0;

                    &:last-of-type {
                        margin: 0;
                    }
                }
            }
        }
    }
}