/* ===========================
Cookies page
=========================== */
.cookies-page {
    .cookies-cover {
        text-align: center;
        padding: rem(60) 0;

        @include breakpoint(min-1280) {
            padding: rem(80) 0;
        }

        h1 {
            font-family: $font-stack-display;
            font-weight: $font-regular;
            margin: 0 auto rem(16) auto;

            @include breakpoint(min-1280) {
                max-width: rem(720);
                font-size: var(--font-size-10);
                line-height: rem(52);
                margin-bottom: rem(24);
            }
        }

        p {
            color: $gray-70;
            margin: 0 auto;
            letter-spacing: -0.6px;
            max-width: rem(660);

            @include breakpoint(min-1280) {
                max-width: rem(720);
                font-size: var(--font-size-5);
                line-height: rem(28);
            }
        }
    }

    .cookies-info {
        margin: rem(16) 0 0 0;
        padding: rem(24) 0;
        background-color: $gray-10;

        @include breakpoint(min-480) {
            margin: rem(24) 0 0 0;
            padding: rem(32) 0;
        }

        @include breakpoint(min-768) {
            margin: rem(32) 0 0 0;
            padding: rem(40) 0;
        }

        @include breakpoint(min-1280) {
            margin: rem(40) 0 0 0;
            padding: rem(60) 0;
        }

        .cookies-info-blocks {
            margin: 0 auto;
            padding: 0;
            list-style-type: none;
            width: 100%;
            max-width: rem(929);

            &__item {
                background-color: $white;
                margin: 0 0 rem(8) 0;
                padding: rem(20) rem(24);

                @include breakpoint(min-768) {
                    padding: rem(38) rem(40);
                }

                &:last-of-type {
                    margin: 0;
                }

                h3 {
                    font-size: var(--font-size-6);
                    line-height: rem(28);
                    font-family: $font-stack-display;
                    font-weight: $font-regular;
                    margin-bottom: rem(16);

                    @include breakpoint(min-768) {
                        font-size: var(--font-size-7);
                        line-height: rem(32);
                        margin-bottom: rem(24);
                    }
                }

                p {
                    color: $gray-70;
                    font-size: var(--font-size-5);
                    line-height: rem(28);
                    letter-spacing: -0.6px;
                    margin-top: rem(16);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @include breakpoint(min-768) {
                        margin-top: rem(24);
                    }

                    strong {
                        color: $gray-90;
                    }
                }
            }
        }
    }

    .cookies-table {
        width: 100%;
        max-width: rem(1465);
        margin: 0 auto rem(24) auto;

        &__head-row {
            background-color: $gray-10;
        }

        &__row {
            .cookies-table__item {
                &:first-of-type {
                    padding: rem(12);
                    text-align: left;
                    font-size: var(--font-size-2);
                    font-weight: $font-semibold;
                    line-height: rem(20);
                    text-transform: uppercase;
                    color: $gray-50;
                    background-color: $gray-10;
                }

                @include breakpoint(min-768) {
                    margin: 0;
                    padding: rem(16);
                    display: table-cell;

                    &:first-of-type {
                        all: inherit;
                        margin: 0;
                        padding: rem(16);
                        display: table-cell;
                    }
                }
            }

            @include breakpoint(min-768) {
                border-bottom: rem(1) solid $gray-10;
            }
        }

        &__head-row {
            .cookies-table__item {
                padding: rem(12);
                text-align: left;
                font-size: var(--font-size-2);
                line-height: rem(20);
                text-transform: uppercase;
                color: $gray-50;
                background-color: $gray-10;
                display: none;

                @include breakpoint(min-768) {
                    display: table-cell;
                    padding: rem(16);
                }
            }
        }

        &__item {
            display: flex;
            padding: 0 rem(8);
            margin: rem(16) 0;

            @include breakpoint(min-768) {
                margin: 0;
                padding: rem(16);
                display: table-cell;
            }
        }
    }
}