/* ===========================
Weeding ring measure page
=========================== */
.lp-jewelry-guide--wedding-ring-measure {
    > .page-wrap {
        max-width: rem(1840);
        width: 100%;
        position: relative;
        padding: 0 rem(12);

        @include breakpoint(min-640) {
            padding: 0 rem(24);
        }

        @include breakpoint(min-768) {
            padding: 0 rem(40);
        }

        @include breakpoint(min-1280) {
            padding: 0 rem(60);
        }

        @include breakpoint(min-1600) {
            width: calc(100% - #{rem(160)});
            padding: 0;
        }

        .breadcrumbs {
            @include breakpoint(min-1280) {
                margin: rem(24) 0;
            }
        }
    }

    .note {
        color: $sc-ruby-red;
        font-weight: $font-semibold;
    }

    .ring-limitations {
        text-align: center;
        margin: 0 auto;

        .o-page-cover__heading-3 {
            max-width: rem(540);
            margin: rem(12) auto rem(24) auto;
            text-align: center;

            @include breakpoint(min-640) {
                margin: rem(20) auto rem(40) auto;
            }

            @include breakpoint(min-768) {
                margin: rem(24) auto rem(60) auto;
            }

            @include breakpoint(min-1600) {
                margin: rem(32) auto rem(80) auto;
            }

        }
    }

    .ring-limitations-list {
        display: inline-flex;
        flex-wrap: wrap;
        margin: rem(-4);
        padding: 0;
        list-style-type: none;

        @include breakpoint(min-768) {
            flex-wrap: nowrap;
            margin: rem(-8);
        }
    }

    .ring-limitations-list__item {
        overflow: hidden;
        width: 100%;
        margin: rem(4);
        display: flex;
        height: rem(400);

        @include breakpoint(min-640) {
            height: rem(280);
        }

        @include breakpoint(min-768) {
            width: calc(33.33% - #{rem(16)});
            margin: rem(8);
            height: rem(548);
        }

        img {
            position: absolute;
            width: auto;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @include breakpoint(min-640) {
                max-width: 100%;
                height: auto;
            }

            @include breakpoint(min-768) {
                max-width: none;
                height: 100%;
            }
        }

        p {
            text-align: center;
            margin: auto auto 0 auto;
            position: relative;
            z-index: 1;
            padding: rem(40);
        }
    }
}


//Cover & box section
.o-page-cover, .o-page-box {
    max-width: rem(1840);
    margin: 0 rem(12) rem(24) rem(12);
    display: flex;
    flex-wrap: wrap;
    background-color: $gray-10;

    @include breakpoint(min-640) {
        margin: 0 rem(24) rem(40) rem(24);
    }

    @include breakpoint(min-768) {
        margin: 0 rem(40) rem(60) rem(40);
    }

    @include breakpoint(min-1280) {
        margin: 0 rem(60) rem(60) rem(60);
        min-height: rem(800);
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    @include breakpoint(min-1600) {
        margin: 0 auto rem(80) auto;
        width: calc(100% - #{rem(160)});
    }

    + .o-page-section, + .o-page-cover {
        margin-top: rem(80);

        @include breakpoint(min-768) {
            margin-top: rem(100);
        }

        @include breakpoint(min-1280) {
            margin-top: rem(160);
        }
    }

    + .note-info {
        text-align: center;
        font-size: var(--font-size-3);
        margin: rem(-12) auto 0 auto;

        @include breakpoint(min-640) {
            margin: rem(-24) auto 0 auto;
        }

        @include breakpoint(min-768) {
            margin: rem(-40) auto 0 auto;
        }

        @include breakpoint(min-1280) {
            font-size: var(--font-size-4);
        }

        + .o-page-section, + .o-page-cover {
            margin-top: rem(80);

            @include breakpoint(min-768) {
                margin-top: rem(100);
            }

            @include breakpoint(min-1280) {
                margin-top: rem(160);
            }
        }
    }
}

//Cover gold theme
.o-page-cover--gold, .o-page-box--gold {
    background-color: $gold-20;

    .o-page-cover__section-heading {
        color: $gold-40;

        &::after {
            background-color: $gold-40;
        }
    }
}

//Simulate page-wrap
.o-page-cover__content, .o-page-box__content, .o-page-section__content {
    order: 1;
    flex: 0 0 92%;
    margin: rem(24) auto;

    @include breakpoint(min-640) {
        margin: rem(32) auto;
    }

    @include breakpoint(min-1280) {
        order: unset;
        flex: 1 1 50%;
        max-width: rem(800);
        margin: 0 0 0 4%;
        padding: rem(80) 4% rem(80) 0;
    }

    + .o-page-cover__image, + .o-page-box__image {
        @include breakpoint(min-1280) {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

.o-page-cover__content-block, .o-page-box__content-block, .o-page-section__content-block {
    text-align: center;
    margin: 0 auto rem(40) auto;

    @include breakpoint(min-768) {
        text-align: left;
        margin: 0 0 rem(60) 0;
    }

    @include breakpoint(min-1280) {
        margin-bottom: rem(80);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.o-page-cover__section-heading, .o-page-box__section-heading {
    display: block;
    text-align: center;
    color: $gray-40;
    font-size: var(--font-size-2);
    line-height: rem(16);
    font-weight: $font-semibold;
    text-transform: uppercase;
    margin: 0 0 rem(12) 0;
    max-width: rem(660);

    @include breakpoint(min-768) {
        font-size: var(--font-size-3);
        display: inline-flex;
        align-items: center;
        margin: 0 0 rem(20) 0;
    }

    @include breakpoint(min-1280) {
        text-align: left;
        font-size: var(--font-size-4);
        line-height: rem(20);
        margin: 0 0 rem(32) 0;
    }

    &::after {
        display: none;
        content: "";
        margin-left: rem(32);
        width: rem(100);
        height: rem(1);
        background-color: $gray-30;

        @include breakpoint(min-768) {
            display: inline-flex;
        }

        @include breakpoint(min-1280) {
            width: rem(150);
            margin-left: rem(40);
        }
    }
}

.o-page-cover__heading-1, .o-page-box__heading-1, .o-page-section__heading-1 {
    display: block;
    text-align: center;
    max-width: rem(660);

    @include breakpoint(min-768) {
        text-align: left;
    }
}

.o-page-cover__heading-2, .o-page-box__heading-2, .o-page-section__heading-2 {
    display: block;
    text-align: center;

    @include breakpoint(min-768) {
        text-align: left;
    }

}

.o-page-cover__heading-3, .o-page-box__heading-3, .o-page-section__heading-3 {
    display: block;
    text-align: center;

    @include breakpoint(min-768) {
        text-align: left;
    }

}

.o-page-cover__desc-big, .o-page-box__desc-big, .o-page-section__desc-big {
    display: block;
    text-align: center;
    color: $gray-70;
    font-size: var(--font-size-4);
    line-height: rem(20);
    margin: 0 auto rem(20) auto;

    @include breakpoint(min-768) {
        text-align: left;
        font-size: var(--font-size-6);
        line-height: rem(28);
        margin: 0 0 rem(20) 0;
    }

    @include breakpoint(min-1280) {
        font-size: var(--font-size-7);
        line-height: rem(32);
        margin: 0 0 rem(32) 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.bold {
        font-weight: $font-semibold;
        color: $gray-90;
    }
}

.o-page-cover__desc-medium, .o-page-box__desc-medium, .o-page-section__desc-medium {
    display: block;
    text-align: center;
    color: $gray-70;
    font-size: var(--font-size-3);
    line-height: rem(16);
    margin: 0 auto rem(20) auto;

    @include breakpoint(min-768) {
        text-align: left;
        font-size: var(--font-size-5);
        line-height: rem(24);
        margin: 0 0 rem(20) 0;
    }

    @include breakpoint(min-1280) {
        font-size: var(--font-size-6);
        line-height: rem(28);
        margin: 0 0 rem(32) 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.bold {
        font-weight: $font-semibold;
        color: $gray-90;
    }

    &.display-font {
        font-family: $font-stack-display;
        max-width: rem(620);
    }
}

.o-page-cover__desc-small, .o-page-box__desc-small, .o-page-section__desc-small {
    display: block;
    text-align: center;
    color: $gray-70;
    font-size: var(--font-size-3);
    line-height: rem(16);
    margin: 0 auto rem(16) auto;

    @include breakpoint(min-768) {
        text-align: left;
        font-size: var(--font-size-4);
        line-height: rem(20);
        margin: 0 0 rem(16) 0;
    }

    @include breakpoint(min-1280) {
        font-size: var(--font-size-5);
        line-height: rem(24);
        margin: 0 0 rem(24) 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.bold {
        font-weight: $font-semibold;
        color: $gray-90;
    }
}

.o-page-cover__image, .o-page-box__image, .o-page-section__image {
    margin: 0 auto;
    overflow: hidden;
    height: rem(280);

    @include breakpoint(min-640) {
        height: auto;
    }

    @include breakpoint(min-1280) {
        flex: 1 0 50%;
        height: rem(800);
        max-width: 50%;
        margin-right: auto;
    }

    img {
        width: auto;
        max-width: none;
        height: 100%;
        margin-left: 50%;
        transform: translateX(-50%);

        @include breakpoint(min-640) {
            width: 100%;
            max-height: rem(560);
        }

        @include breakpoint(min-1280) {
            width: auto;
           max-height: none;
        }
    }
}

.o-page-cover__unordered-list, .o-page-box__unordered-list {
    padding: 0;
    margin: 0 0 rem(24) 0;
    list-style-type: none;

    @include breakpoint(min-768) {
        margin-bottom: rem(32);
    }

    @include breakpoint(min-1280) {
        margin-bottom: rem(40);
    }
}

.o-page-cover__unordered-list-item, .o-page-box__unordered-list-item {
    color: $gray-70;
    font-size: var(--font-size-3);
    line-height: rem(20);
    margin-bottom: rem(16);
    padding-left: rem(26);
    text-align: left;

    @include breakpoint(min-1280) {
        font-size: var(--font-size-4);
        line-height: rem(24);
        margin-bottom: rem(20);
        padding-left: rem(38);
    }

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        left: rem(8);
        top: rem(-2);
        font-size: var(--font-size-6);
        color: $gray-40;
        content: "•";

        @include breakpoint(min-1280) {
            font-size: var(--font-size-7);
            left: rem(16);
        }
    }
}

    //Stripe block
.c-stripe-divider {
    display: block;
    background-color: $gold-40;
    width: rem(1);
    height: rem(60);
    margin: rem(24) auto;

    @include breakpoint(min-640) {
        height: rem(120);
        margin: rem(32) auto;
    }

    @include breakpoint(min-1280) {
        height: rem(200);
        margin: rem(40) auto;
    }
}

//Page section
.o-page-section {
    max-width: rem(1840);
    overflow: hidden;
    padding: 0 rem(12) rem(24) rem(12);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(min-640) {
        padding: 0 rem(24) rem(40) rem(24);
    }

    @include breakpoint(min-768) {
        padding: 0 rem(40) rem(60) rem(40);
    }

    @include breakpoint(min-1280) {
        padding: 0 rem(60) rem(60) rem(60);
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    @include breakpoint(min-1600) {
        padding: 0 0 rem(80) 0;
        width: calc(100% - #{rem(160)});
    }

    + .o-page-section, + .o-page-cover {
        padding-top: rem(80);

        @include breakpoint(min-768) {
            padding-top: rem(100);
        }

        @include breakpoint(min-1280) {
            padding-top: rem(160);
        }
    }
}

.o-page-section__content--center {
   text-align: center;
    padding: 0;
    margin: 0 auto;

    * {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .c-link {
        margin-top: rem(20);

        @include breakpoint(min-768) {
            margin-top: rem(32);
        }

        @include breakpoint(min-1280) {
            margin-top: rem(40);
        }
    }
}

//Modification for basic style -> .o-page-cover__image, .o-page-box__image, .o-page-section__image style
.o-page-section__image {
    position: relative;

    @include breakpoint(min-640) {
        height: auto;
        overflow: visible;
    }

    img {
        @include breakpoint(min-1280) {
            width: 100%;
            height: auto;
            max-height: none;
        }
    }
}

.o-page-section__image--center {
    width: 100%;
    height: auto;
    margin: rem(80) auto 0 auto;
    text-align: center;
    flex: 1 1 auto;
    max-width: none;
    overflow: visible;

    img {
        display: block;
        max-width: rem(920);
        width: auto;
        height: auto;
        position: relative;
        left: 50%;
        max-height: none;
        margin-left: 0;
        transform: translateX(-50%);

        @include breakpoint(min-1280) {
            max-width: rem(1840);
        }
    }
}

//Link, button link
.c-link {
    display: inline-block;
    background-color: transparent;
    border: none;
    color: $gray-90;
    font-size: var(--font-size-3);
    line-height: rem(20);
    letter-spacing: rem(1);
    font-weight: $font-semibold;
    text-transform: uppercase;
    margin: 0 auto;
    text-decoration: none;

    &:hover, &:focus {
        &::after {
            transform: translateY(0);
        }
    }

    &:focus {
        outline: none;
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: rem(2);
        background-color: $gray-90;
        transform: translateY(2px);
        transition: transform .2s ease-in-out;
    }
}

//Modal
.l-modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($gray-90,.7);
    overflow-y: auto;
    margin: auto;
    z-index: 10000;

    &.show {
        display: flex;
    }
}

.o-modal {
    background-color: $white;
    width: calc(100% - #{rem(16)});
    max-width: rem(840);
    margin: auto;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(min-640) {
      flex-wrap: nowrap;
    }

    .link {
        color: $gray-90;
    }

    .c-input-label__label {
        text-align: left;
    }

    .btn--primary {
        border-radius: 0;
        background-color: $gray-90;
        border-color: $gray-90;
        width: 100%;
        margin: rem(24) 0;

        &:hover {
            box-shadow: 0 0 0 rem(2) $gray-90;
        }

        &:focus {
            outline: none;
        }
    }
}

.o-modal__close-btn {
    z-index: $z-index-1;
    position: absolute;
    top: rem(16);
    right: rem(16);
    border: none;
    background-color: $white;
    border-radius: 100%;
    width: rem(48);
    height: rem(48);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow .2s ease-in-out;

    &:hover {
        box-shadow: 0 0 rem(16) 0 rgba($gray-90,.15);

        .icon {
            fill: $gray-70;
        }
    }

    &:focus {
        outline: none;
    }

    .icon {
        width: rem(16);
        height: rem(16);
        fill: $gray-50;
        transition: fill .2s ease-in-out;
    }
}

.o-modal__cover {
    overflow: hidden;
    position: relative;

    @include breakpoint(min-640) {
        order: 1;
        flex: 1 1 rem(400);
    }

    img {
        width: 100%;
        height: auto;
        display: block;

        @include breakpoint(min-640) {
            width: auto;
            height: rem(520);
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
}

.o-modal__content {
    padding: rem(24);
    text-align: center;

    @include breakpoint(min-640) {
        margin: auto;
        padding: rem(32);
        flex: 1 1 rem(440);
    }
}

.o-modal__heading {
    font-family: $font-stack-text;
    font-weight: bold;
    display: block;
    text-align: center;
    font-size: var(--font-size-5);
    line-height: rem(20);
    margin-bottom: rem(12);

    @include breakpoint(min-640) {
        text-align: left;
        font-size: var(--font-size-7);
        line-height: rem(32);
        margin-bottom: rem(16);
    }
}

.o-modal__desc {
    display: block;
    text-align: center;
    color: $gray-70;
    font-size: var(--font-size-3);
    line-height: rem(16);
    margin: 0 auto rem(24) auto;

    @include breakpoint(min-640) {
        text-align: left;
        font-size: var(--font-size-5);
        line-height: rem(24);
        margin: 0 0 rem(32) 0;
    }

    @include breakpoint(min-1280) {

    }

    &:last-child {
        margin-bottom: 0;
    }

    &.bold {
        font-weight: $font-semibold;
        color: $gray-90;
    }
}

.o-modal__desc-small {
    display: block;
    text-align: center;
    color: $gray-70;
    font-size: var(--font-size-2);
    line-height: rem(16);
    margin: 0 auto rem(16) auto;

    @include breakpoint(min-640) {
        text-align: left;
        font-size: var(--font-size-3);
        line-height: rem(20);
        margin: 0 0 rem(16) 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.bold {
        font-weight: $font-semibold;
        color: $gray-90;
    }
}