/* Variables */

$basefont: 16;
$page-context: 1600;
$font-stack-display: "Canela web", sans-serif;
$font-stack-text: "plusjakarta", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-regular: 400;
$font-semibold: 600;


/* Color */
$white: #FFFFFF;
$black: #000000;

$gray-90: #1A1919; // eerie black
$gray-80: #2B2A2A; // charleston
$gray-70: #454343; // arsenic 
$gray-60: #7B7B7B; 
$gray-50: #919191; // taupe gray
$gray-40: #C4C4C4; // silver
$gray-30: #DBDBDB; // platinum
$gray-20: #EBEBEB; // isabelline
$gray-10: #F5F5F5; // white-smoke

$yellow-80: #4D3F13;
$yellow-70: #806920;
$yellow-30: #FFF6D9;

$indigo: #540D6E;
$go-green: #0EAD69;
$paradise-pink: #EE4266;
$sunglow: #FFD23F;

$pink-90: #63202E;
$pink-80: #71232A;
$pink-70: #7A3040; // Danfil Kids Brand #1 color
$pink-60: #9D5F6C;
$pink-50: #BD7B86;
$pink-40: #D69DA5;
$pink-30: #EDD5D1;
$pink-20: #F7E6E3; // Danfil Kids Brand #2 color
$pink-10: #FFF4F2;

$gold-70: #7C6B58;
$gold-60: #7F7260;
$gold-50: #AE906F;
$gold-40: #B29A83;
$gold-30: #D1C0B6;
$gold-20: #F0E6DB;

/* Socials colors */
$facebook-color: #3B5998;
$twitter-color: #1D9AD8;
$instagram-color: #3f729b;
$pinterest-color: #CC2127;
$google-plus-color: #CD4C39;
$youtube-color: #cd201f;

$white-gold: #D5D5D6;
$yellow-gold: #E0D0AA;
$rose-gold: #E7C2A9;

$sc-white: #fff;
$sc-pink: #FF94B0;
$sc-red: #E35185;
$sc-ruby-red: #BE1E2D;
$sc-purple: #7F519B;
$sc-dark-blue: #3636A1;
$sc-light-blue: #8896FF;
$sc-mint-green: #4FFFD7;
$sc-smaragd-green: #00B364;
$sc-olive-green: #21E05E;
$sc-yellow: #FFF363;
$sc-orange: #FF8700;

$gold-70: #7C6B58;
$gold-60: #7F7260;
$gold-50: #AE906F;
$gold-40: #B29A83;
$gold-20: #F0E6DB;

$test: #000;
$test2: #000;

/* Functions */

@function percent($target, $container) {
	@return calc($target / $container) * 100%;
}

@function em($target, $context: $basefont) {
	@return calc($target / $context) * 1em;
}


@function rem($size) {
  $remSize: calc($size / 16);
  @return $remSize + rem;
}


/* Breakpoints */

@mixin breakpoint($point) {

	@if $point == min-480 {
		@media screen and (min-width: 480px) { @content; }
	}

	@else if $point == min-640 {
		@media screen and (min-width: 640px) { @content; }
	}

	@else if $point == min-768 {
		@media screen and (min-width: 768px) { @content; }
	}

	@else if $point == min-960 {
		@media screen and (min-width: 960px) { @content; }
	}

	@else if $point == min-1024 {
		@media screen and (min-width: 1024px) { @content; }
	}

	@else if $point == min-1280 {
		@media screen and (min-width: 1280px) { @content; }
	}

	@else if $point == min-1600 {
		@media screen and (min-width: 1600px) { @content; }
	}

}


/* Retina images */

@mixin image-2x($image, $width, $height:"") {
	@media (min--moz-device-pixel-ratio: 1.3),
				 (-o-min-device-pixel-ratio: 2.6/2),
				 (-webkit-min-device-pixel-ratio: 1.3),
				 (min-device-pixel-ratio: 1.3),
				 (min-resolution: 1.3dppx) {
		background-image: url($image);
		background-size: $width unquote($height);
	}
}


@keyframes loader-rotate {
	0% {
	transform: rotate(0);
	}

	100% {
	transform: rotate(360deg);
	}
}


/* Z-Index Scale */

$z-index-1:   100;
$z-index-2:   200;
$z-index-3:   300;
$z-index-4:   400;
$z-index-5:   500;
$z-index-6:   600;
$z-index-7:   700;
$z-index-8:   800;
$z-index-9:   900;
$z-index-10: 1000;
$z-index-12: 1200; /* used for cookie modal which has to be over everything */
$z-index-13: 1300; /* used for cookie modal which has to be over everything */

/* Cubic bezier transitions */
$ease-in-quad: cubic-bezier(.55, .085, .68, .53);
$ease-in-cubic: cubic-bezier(.550, .055, .675, .19);
$ease-in-quart: cubic-bezier(.895, .03, .685, .22);
$ease-in-quint: cubic-bezier(.755, .05, .855, .06);
$ease-in-expo: cubic-bezier(.95, .05, .795, .035);
$ease-in-circ: cubic-bezier(.6, .04, .98, .335);

$ease-out-quad: cubic-bezier(.25, .46, .45, .94);
$ease-out-cubic: cubic-bezier(.215, .61, .355, 1);
$ease-out-quart: cubic-bezier(.165, .84, .44, 1);
$ease-out-quint: cubic-bezier(.23, 1, .32, 1);
$ease-out-expo: cubic-bezier(.19, 1, .22, 1);
$ease-out-circ: cubic-bezier(.075, .82, .165, 1);

$ease-in-out-quad: cubic-bezier(.455, .03, .515, .955);
$ease-in-out-cubic: cubic-bezier(.645, .045, .355, 1);
$ease-in-out-quart: cubic-bezier(.77, 0, .175, 1);
$ease-in-out-quint: cubic-bezier(.86, 0, .07, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(.785, .135, .15, .86);