/* ===========================
Latest jewelry carousel - component
=========================== */
.c-latest-jewelry-carousel {
    margin: var(--space-8) 0;

    @include breakpoint(min-768) {
        margin: var(--space-20) 0;
    }
}

.c-latest-jewelry-carousel__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 var(--space-8) 0;

    @include breakpoint(min-768) {
        margin: 0 0 var(--space-10) 0;
    }
}

.c-latest-jewelry-carousel__content {
    position: relative;
    margin: 0;
    width: 100%;
}

.c-latest-jewelry-carousel__heading {
    display: block;
    margin-bottom: var(--space-2);
   
    @include breakpoint(min-768) {
        margin-bottom: var(--space-4);
        margin-right: var(--space-40);
    }
}

.c-latest-jewelry-carousel__desc {
    display: block;
    color: var(--gray-70);
    margin: 0 0 var(--space-8) 0;

    @include breakpoint(min-768) {
        margin: 0 var(--space-40) var(--space-10) 0;
    }

    @include breakpoint(min-1280) {
        margin: 0 0 var(--space-16) 0;
    }
}

.c-latest-jewelry-carousel__link {
    @include breakpoint(min-768) {
       position: absolute;
       right: 0;
       bottom: 0;
    }
}

// Custom CSS for carousel - Needs to be updated/unified in the future
.c-latest-jewelry-carousel .products {
    margin: 0;
}