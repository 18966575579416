/* ===========================
5c sitemap list
=========================== */
.c-5c-sitemap {
   flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    margin: rem(-20) rem(-12);
    padding: 0;
    list-style-type: none;

    @include breakpoint(min-768) {
        margin: rem(-40) rem(-12);
    }
}

.c-5c-sitemap__list-item {
    margin: rem(20) rem(12);
    flex: 1 1 100%;

    @include breakpoint(min-768) {
        flex: 0 1 calc(50% - #{rem(24)});
        margin: rem(40) rem(12);
    }

    @include breakpoint(min-1280) {
        flex: 0 1 calc(25% - #{rem(24)});
    }
}

.c-5c-sitemap__list-item picture {
    overflow: hidden;
    display: block;
    margin-bottom: rem(24);
}

.c-5c-sitemap__list-item img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform .4s ease-in;

    &:hover {
        transform: scale(1.05);
    }
}

.c-5c-sitemap__list-item h2 {
    font-family: $font-stack-text;
    font-weight: $font-semibold;
    margin-bottom: rem(8);

    @include breakpoint(min-768) {
        text-align: left;
    }

    @include breakpoint(min-1280) {
        margin-bottom: rem(12);
    }
}

.c-5c-sitemap__list-item p {
    display: block;
    color: $gray-70;
    margin: 0 0 rem(16) 0;

    @include breakpoint(min-1280) {
        margin: 0 0 rem(24) 0;
    }
}