/* ===========================
About us page
=========================== */
.about-us-page {
	//Common style for all section
	.about-us-cover,
	.about-us-claim,
	.about-us-manufacturing-process,
	.about-us-testimonial,
	.about-us-team,
	.about-us-collections,
	.about-us-career {

		&__image {
			width: 100%;

			img {
				width: 100%;
			}
		}

		&__text {
			width: 100%;

			h1, h2 {
				font-family: $font-stack-display;
				font-weight: $font-regular;
				margin-bottom: rem(16);

				@include breakpoint(min-1280) {
					font-size: var(--font-size-9);
					line-height: rem(52);
					margin-bottom: rem(24);
				}

				small {
					color: $gray-50;
					font-family: $font-stack-text;
					font-size: var(--font-size-3);
					line-height: rem(14);
					font-weight: $font-semibold;
					text-transform: uppercase;
					display: block;
				}
			}

			p {
				color: $gray-70;
				font-size: var(--font-size-5);
				line-height: rem(28);
				letter-spacing: -0.6px;
				margin: 0 0 rem(16) 0;
				padding: 0;

				&:last-of-type {
					margin: 0;
				}
			}

			strong {
				color: $gray-90;
				font-weight: $font-semibold;
				line-height: rem(24);
			}

			.link-arrow {
				display: table;
				margin: 0 auto;
				color: $gray-90;
				box-shadow: 0 rem(1) 0 0 $gray-90;
				text-decoration: none;
				font-size: var(--font-size-3);
				line-height: rem(28);
				text-transform: uppercase;
				font-weight: $font-semibold;
				position: relative;
				transition: box-shadow .2s $ease-in-out-quad;

				&:hover {
					box-shadow: 0 rem(2) 0 0 $gray-90;
				}

				&:hover .icon-arrow-right {
					transform: translateX(rem(6));
				}

				@include breakpoint(min-768) {
					display: inline-block;
					margin-left: percent(64, 768);
				}

				@include breakpoint(min-960)    { margin-left: percent(160, 960); }
				@include breakpoint(min-1024)   { margin-left: percent(193, 1024); }
				@include breakpoint(min-1280)   { margin-left: 0; }

			}

			.icon-arrow-right {
				position: absolute;
				top: rem(8);
				right: - rem(16);
				display: block;
				width: rem(12);
				height: rem(12);
				transition: transform .2s $ease-in-out-quad;
			}
		}
	}

	//Header section
	.about-us-cover {
		padding: rem(40) 0 rem(60) 0;

		@include breakpoint(min-1280) {
			padding: rem(60) 0 rem(80);
		}

		@include breakpoint(min-1280) {
			padding: rem(120) 0 rem(80);
		}

		&__text {
			@include breakpoint(min-1280) {
				max-width: rem(593);
				margin: 0 auto 0 0;
			}

			h1 {
				@include breakpoint(min-1280) {
					font-size: var(--font-size-10);
				}
			}

			p:first-of-type {
				color: $gray-80;
				font-family: $font-stack-display;
				font-weight: $font-regular;

				@include breakpoint(min-640) {
					font-size: var(--font-size-7);
					line-height: rem(32);
					letter-spacing: - 0.08rem;
					margin-bottom: rem(32);
				}
			}
		}

		&__buttons {
			list-style-type: none;
			margin: 0 0 rem(40) 0;
			padding: 0;
			display: flex;
			flex-direction: column;

			@include breakpoint(min-1024) {
				flex-direction: row;
				margin: 0;
			}
			
			li:first-child {
				margin-bottom: rem(24);

				@include breakpoint(min-1024) {
					flex-basis: rem(260);
					margin-right: rem(40);
				}
			}

			li:last-child {
				color: $gray-70;

				a {
					color: $gray-90;
				}
			}

			.btn {
				width: 100%;
				display: inline-block;
			}
		}

		&__image {
			@include breakpoint(min-640)    { margin-left: rem(80); }
			@include breakpoint(min-1280)   { max-width: rem(794); }
		}

		p:last-of-type {
			margin-bottom: rem(32);
		}

	}

	//Claim section
	.about-us-claim {
		text-align: center;
		margin: 0 auto;
		padding: rem(80) 0 rem(100) 0;

		h2 {
			font-family: $font-stack-display;
			font-weight: $font-regular;
			margin-bottom: rem(16);

			@include breakpoint(min-1280) {
				font-size: var(--font-size-9);
				line-height: rem(52);
				margin-bottom: rem(24);
			}
		}

		p {
			color: $gray-70;
			font-size: var(--font-size-5);
			line-height: rem(28);
			letter-spacing: -0.6px;
			margin: 0 auto;
			padding: 0;

			@include breakpoint(min-1280) {
				max-width: rem(593);
			}
		}

		.btn {
			max-width: rem(360);
			margin: rem(40) auto 0;
		}

	}

	//Manufacturing process section
	.about-us-manufacturing-process {
		background-color: $gray-10;
		padding: rem(60) 0;

		@include breakpoint(min-1280) {
			padding: rem(80) 0;
		}

		&__text {
			@include breakpoint(min-1280) {
				max-width: rem(593);
				margin: 0 auto;
			}

			p {
				&:last-of-type {
					margin: 0 0 rem(32) 0;
				}
			}
		}

		&__image {
			margin-bottom: rem(40);

			@include breakpoint(min-640) {
				margin-right: rem(40);
				margin-bottom: 0;
			}
			
			@include breakpoint(min-1280) {
				max-width: rem(724);
				margin-right: rem(40);
			}
		}
	}

	//Testimonial section
	.about-us-testimonial {
		background-color: $gray-10;
		padding: rem(60) 0;

		@include breakpoint(min-1280) {
			padding: rem(80) 0 rem(120) 0;
		}

		&__text {
			@include breakpoint(min-1280) {
				position: relative;
				margin-bottom: rem(60);
			}


			h2 {
				position: relative;
				margin:rem(32) 0 rem(40) 0;
				padding-left: rem(92);

				@include breakpoint(min-640) {
					margin:rem(32) 0 rem(4) 0;
				}


				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: rem(30);
					background-color: $gray-40;
					width: rem(60);
					height: rem(2);
				}

				small {
					color: $gray-90;
					font-size: var(--font-size-5);
					line-height: rem(24);
				}
			}

			p {
				background-color: $white;
				padding: rem(20);
				font-family: $font-stack-display;
				font-weight: $font-regular;

				@include breakpoint(min-1280) {
					width: calc(100% + (#{rem(56)}));
					max-width: rem(862);
					padding: rem(40);
					font-size: var(--font-size-7);
					line-height: rem(40);
					letter-spacing: -.8px;
				}
			}
		}
	}

	//Our team section
	.about-us-team {
		padding: rem(60) 0;

		@include breakpoint(min-1280) {
			padding: rem(80) 0;
		}

		&__text {
			z-index: $z-index-5;
			padding: rem(80) rem(12) rem(40) rem(12);

			h2 {
				text-align: center;
			}

			p {
				text-align: center;
			}

			@include breakpoint(min-1280) {
				max-width: rem(593);
				margin: 0 auto rem(100) auto;
			}
		}

		&__image {
			position: absolute;
			top: 0;
			left: 0;
			@include breakpoint(min-1280) {
				max-width: rem(724);
				margin-right: rem(40);
			}

			img {
				max-width: 100%;
				width: calc(100% + (#{rem(356)}));
			}
		}

		
	}

	.c-employees-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;

		&__avatar {
			overflow: hidden;
			border-radius: 100%;
			background-color: $gray-20;
			width: rem(80);
			height: rem(80);
			margin-bottom: rem(20);

			@include breakpoint(min-640){
				margin: 0 rem(24) 0 0;
			}

			img {
				width: 100%;
			}
		}

		&__name {

			h3 {
				font-family: $font-stack-display;
				font-weight: $font-regular;
				font-size: var(--font-size-7);
				line-height: rem(32);
				margin-bottom: rem(4);
				text-align: center;

				@include breakpoint(min-640){
					text-align: left;
				}
			}

			strong {
				color: $gray-70;
				font-weight: $font-semibold;
				font-size: var(--font-size-4);
				line-height: rem(24);
			}
		}

		&__desc {
			color: $gray-70;
			font-size: var(--font-size-4);
			line-height: rem(24);
			margin-bottom: rem(24);
			letter-spacing: -.5px;
			text-align: center;

			@include breakpoint(min-640){
				text-align: left;
			}

			&:first-of-type {
				margin-top: rem(32);
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.l-employees-item {
		background-color: $white;
		padding: rem(20);
		margin: rem(8);

		@include breakpoint(min-960) {
			flex: 1 1 calc(50% - #{rem(10)});
		}

		@include breakpoint(min-1280) {
			flex: 1 1 calc(#{percent(500,1520)} - #{rem(10)});
			padding: rem(24) rem(24) rem(32) rem(24);
		}
	}

	.l-team-bgcolor-wrapper {
		background-color: $gray-10;
	}

	.l-team-intro-wrapper {
		position: relative;
		max-height: rem(596);
	}

	.l-team-employees-wrapper {
		padding: rem(12);

		@include breakpoint(min-640) {
			padding: rem(16);
		}

		@include breakpoint(min-768) {
			padding: rem(24);
		}

		@include breakpoint(min-960) {
			padding: rem(40);
		}
	}

	//Collections section
	.about-us-collections {
		background-color: $gray-10;
		padding: rem(60) 0;
		margin: 0 auto;
		text-align: center;

		@include breakpoint(min-1280) {
			padding: rem(60) 0 rem(80) 0;
		}

		h2 {
			font-family: $font-stack-display;
			font-weight: $font-regular;
			margin-bottom: rem(16);

			@include breakpoint(min-1280) {
				font-size: var(--font-size-9);
				line-height: rem(52);
				margin-bottom: rem(62);
			}

			small {
				color: $gray-50;
				font-family: $font-stack-text;
				font-size: var(--font-size-3);
				line-height: rem(14);
				font-weight: $font-semibold;
				text-transform: uppercase;
				display: block;
			}
		}
	}

	.c-collection-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		text-align: left;

		&__image {
			margin: 0 auto;
			
			@include breakpoint(min-640) {
				margin: 0 rem(20) 0 0;
			}

			@include breakpoint(min-768) {
				margin: 0 rem(40) 0 0;
			}
		}

		&__copy {

			h3 {
				font-family: $font-stack-display;
				font-weight: $font-regular;
				font-size: var(--font-size-7);
				line-height: rem(32);
				margin-bottom: rem(12);
				text-align: center;

				@include breakpoint(min-640) {
					text-align: left;
				}
			}

			p {
				text-align: center;
				color: $gray-70;
				font-size: var(--font-size-4);
				line-height: rem(24);
				letter-spacing: -.5px;
				margin-bottom: rem(24);
				max-width: 100%;

				@include breakpoint(min-640) {
					text-align: left;
				}

				@include breakpoint(min-960) {
					max-width: rem(446);
				}
			}

			.btn {
				max-width: rem(200);
				margin: 0 auto;
			
				@include breakpoint(min-640) {
					margin: 0;
				}
			}
		}
	}

	.l-collection-item {
		background-color: $white;
		flex: 1 1 100%;
		width: 100%;
		margin-bottom: rem(10);
		padding: rem(40);

		@include breakpoint(min-1280) {
			flex: 1 1 calc(#{percent(795,1600)} - #{rem(10)});
			max-width: rem(795);
			margin: 0 rem(10) rem(10) 0;
			padding: rem(40);

			&:nth-of-type(2n) {
				margin: 0 0 rem(10) 0;
			}
		}

		.l-about-us-section-wrapper {
			align-items: flex-start;
		}
	}

	//Career section
	.about-us-career {
		padding: rem(60) 0;

		@include breakpoint(min-1280) {
			padding: rem(80) 0;
		}

		&__text {

			@include breakpoint(min-640) {
				width: 55%;
			}

			p:last-of-type {
				margin-bottom: rem(40);
				
				@include breakpoint(min-640) {
					margin-bottom: 0;
				}
			}

			@include breakpoint(min-1280) {
				max-width: rem(593);
				margin: 0 auto 0 0;
			}

			a {
				color: $gray-90;
				font-weight: $font-semibold;

				&:hover {
					text-decoration: none;
				}
			}
		}

		&__image {

			@include breakpoint(min-640) {
				width: 40%;
				margin-left: auto;
			}

			@include breakpoint(min-1280) {
				max-width: rem(724);
				margin-right: rem(40);
			}
		}
	}

	//Common wrapper for section
	.l-about-us-section-wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;

		@include breakpoint(min-640) {
			flex-direction: row;
		}
	}
}