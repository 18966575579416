/* ===========================
Error pages - 404
=========================== */

.error-pages {


    .breadcrumbs {
        position: absolute;
        top: 0;
    }

    .error-pages-cover {
        position: relative;

        &__image {
            display: flex;
            align-items: center;
            min-height: rem(400);
            background: $white;
            overflow: hidden;

            @include breakpoint(min-640) {
                min-height: rem(480);
            }

            @include breakpoint(min-960) {
                min-height: rem(560);
            }

            @include breakpoint(min-1280) {
                min-height: rem(640);
            }

            picture {
                overflow: hidden;
                position: absolute;
                height: 100%;
                display: block;
                left: 0;
                top: 0;
                width: 100%;
            }

            img {
                position: absolute;
                height: auto;
                min-height: 100%;
                min-width: 100%;
                left: 50%;
                transform: translateX(-50%);
            }

            figcaption {
                z-index: $z-index-1;
                text-align: center;
                padding: rem(24) 0;

                @include breakpoint(min-960) {
                    text-align: left;
                }
            }

            @include breakpoint(min-1280) {
                height: rem(436);
            }
        }

        &__heading {
            font-size: var(--font-size-9);
            line-height: rem(44);
            max-width: rem(593);
            font-family: $font-stack-display;
            font-weight: $font-regular;
            margin: 0 auto rem(16) auto;

            small {
                color: $gray-50;
                font-family: $font-stack-text;
                font-size: var(--font-size-3);
                line-height: rem(14);
                font-weight: $font-semibold;
                text-transform: uppercase;
                display: block;
                margin-bottom: rem(4);
            }

            @include breakpoint(min-768) {
                font-size: var(--font-size-10);
                line-height: rem(52);
                margin: 0 auto rem(24) auto;
            }
            @include breakpoint(min-960) {
                margin: rem(12) auto rem(32) 0;
            }
        }

        &__desc {
            color: $gray-70;
            font-size: var(--font-size-4);
            line-height: rem(24);
            letter-spacing: -0.6px;
            margin: 0 auto rem(24) auto;
            padding: 0;
            max-width: rem(560);

            @include breakpoint(min-768) {
                font-size: var(--font-size-5);
                line-height: rem(28);
            }

            @include breakpoint(min-960) {
                margin: 0 auto rem(32) 0;
            }
        }

        &__btn {
            max-width: rem(280);
            margin: 0 auto;

            @include breakpoint(min-960) {
                margin: 0;
            }
        }
    }

    .error-pages-collections {
        margin: 0;
        padding: rem(32) 0;
        background-color: $gray-10;

        @include breakpoint(min-480) {
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            padding: rem(60) 0;
        }

        &__text {
            text-align: center;
            max-width: rem(593);
            margin: 0 auto rem(32) auto;

            @include breakpoint(min-1280) {
                max-width: rem(640);
                margin: 0 auto rem(40) auto;
            }

            h2 {
                font-family: $font-stack-display;
                font-weight: $font-regular;

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                }

                small {
                    color: $gray-50;
                    font-family: $font-stack-text;
                    font-size: var(--font-size-3);
                    line-height: rem(14);
                    font-weight: $font-semibold;
                    text-transform: uppercase;
                    display: block;
                }
            }
        }

        &__btn {
            max-width: rem(248);
            margin: rem(24) auto 0 auto;

            @include breakpoint(min-640) {
                margin: rem(32) auto 0 auto;
            }

            @include breakpoint(min-768) {
                margin: rem(40) auto 0 auto;
            }
        }
    }
}