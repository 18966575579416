/* ===========================
Terms and conditions page
=========================== */
.terms-and-conditions-page {
    .terms-and-conditions-cover {
        text-align: center;
        padding: rem(60) 0;

        @include breakpoint(min-1280) {
            padding: rem(80) 0;
        }

        h1 {
            margin: 0 auto rem(16) auto;

            @include breakpoint(min-1280) {
                max-width: rem(720);
            }
        }

        p {
            margin: 0 auto;
            max-width: rem(660);

            @include breakpoint(min-1280) {
                max-width: rem(720);
            }
        }
    }

    .terms-and-conditions-info {
        margin: rem(16) 0 0 0;
        padding: rem(24) 0;
        background-color: $gray-10;

        @include breakpoint(min-480) {
            margin: rem(24) 0 0 0;
            padding: rem(32) 0;
        }

        @include breakpoint(min-768) {
            margin: rem(32) 0 0 0;
            padding: rem(40) 0;
        }

        @include breakpoint(min-1280) {
            margin: rem(40) 0 0 0;
            padding: rem(60) 0;
        }

        .terms-and-conditions-info-blocks {
            margin: 0 auto;
            padding: 0;
            list-style-type: none;
            width: 100%;
            max-width: rem(929);

            &__item {
                background-color: $white;
                margin: 0 0 rem(8) 0;
                padding: rem(20) rem(24);

                @include breakpoint(min-768) {
                    padding: rem(38) rem(40);
                }

                &:last-of-type {
                    margin: 0;
                }

                p {
                    margin-top: rem(16);
                    padding: 0 0 0 rem(48);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @include breakpoint(min-768) {
                        margin-top: rem(24);
                    }

                    strong {
                        color: $gray-90;
                    }

                    span {
                        position: absolute;
                        margin-left: rem(-48);
                    }
                }

                ul {
                    margin: 0 0 0 rem(68);
                    padding: 0;
                    list-style-type: none;

                    li {
                        line-height: rem(24);
                        padding: 0 0 0 rem(24);
                        margin: 0 0 rem(8) 0;

                        &:last-child {
                            margin: 0;
                        }

                        &::before {
                            content: "";
                            width: rem(8);
                            height: rem(2);
                            background-color: $gray-40;
                            position: absolute;
                            left: 0;
                            top: rem(12);
                        }
                    }
                }
            }
        }
    }
}