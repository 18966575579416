.c-advantages-promo {
	background-color: $gray-10;
	padding: rem(20) 0 rem(40) 0;

	@include breakpoint(min-640) {
		padding: rem(40) 0 rem(60) 0;
	}

	@include breakpoint(min-768) {
		padding: rem(60) 0 rem(80) 0;
	}

	@include breakpoint(min-1024) {
		padding: rem(80) 0 rem(100) 0;
	}

	@include breakpoint(min-1600) {
		padding: rem(100) 0 rem(140) 0;
	}
}

.c-advantages-promo__heading {
	text-align: center;
	margin: 0 auto rem(28) auto;

	@include breakpoint(min-768) {
		max-width: percent(640, 768);
		text-align: left;
		margin-bottom: rem(40);
	}

	@include breakpoint(min-960) {
		max-width: percent(640, 960);
		
	}

	@include breakpoint(min-1024) {
		max-width: 100%;
		text-align: center;
		margin-bottom: rem(52);
	}
}

.c-advantages-promo__list {
	display: flex;
	flex-direction: column;
	list-style-type: none;
	margin: 0;
	padding: 0;
	counter-reset: adv-counter;

	@include breakpoint(min-768) {
		width: percent(640, 768);
		margin: 0 auto;
	}

	@include breakpoint(min-960) {
		width: percent(640, 960);
	}

	@include breakpoint(min-1024) {
		flex-direction: row;
		width: 100%;
	}
}

.c-advantages-promo__list-item {
	align-self: flex-end;
	flex: 0 0 100%;
	position: relative;
	padding-left: rem(60);
	margin-bottom: rem(24);

	@include breakpoint(min-640) {
		margin-bottom: rem(44);
	}

	@include breakpoint(min-1024) {
		align-self: flex-start;
		flex: 0 0 33.33%;
		padding: rem(72) rem(16) 0 rem(16);
	}

	@include breakpoint(min-1600) {
		padding: rem(72) rem(48) 0 rem(48);
	}
}

.c-advantages-promo__list-item::before {
	content: counter(adv-counter)'.';
	counter-increment: adv-counter;
	position: absolute;
	top: 0;
	left: 0;
	color: rgba($gray-40, .5);
	font-family: $font-stack-display;
	font-size: var(--font-size-10);
	line-height: rem(60); 

	@include breakpoint(min-1024) {
		width: 100%;
		text-align: center;
	}
}


.c-advantages-promo h3 {
	text-transform: uppercase;
	margin-bottom: rem(4);

	@include breakpoint(min-1024) {
		text-align: center;
		margin-bottom: rem(16);
	}
}

.c-advantages-promo p {
	margin: 0;

	@include breakpoint(min-1024) {
		text-align: center;
	}
}

.c-advantages-promo a {
	text-decoration: underline;
	color: $gray-90;
	font-weight: $font-semibold;

	&:hover {
		text-decoration: none;
	}
}

.c-advantages-promo .btn {
	display: block;
	margin: 0 auto;
	width: 100%;
	max-width: rem(400);
}



.c-advantages > .u-page-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.c-advantages__desc {
	margin: 0 0 rem(40) 0;
	max-width: 60ch;

	@include breakpoint(min-960) {
		margin: 0 0 rem(60) 0;
	}
}

.c-advantages__list {
	display: flex;
	list-style-type: none;
	margin: 0 - rem(8);
	padding: 0;
	flex-direction: column;

	@include breakpoint(min-640) {
		flex-direction: row;
	}

	@include breakpoint(min-960) {
		margin: 0 - rem(20);
	}
}

.c-advantages__list-item {
	font-size: var(--font-size-4);
	line-height: rem(20);
	font-weight: $font-semibold;
	color: $gray-90;
	max-width: 16ch;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 rem(40) 0;

	@include breakpoint(min-640) {
		margin: 0 rem(8);
	}

	@include breakpoint(min-960) {
		margin: 0 rem(20);
	}
}

.c-advantages__list-item .icon {
	width: rem(40);
	height: rem(40);
	margin-bottom: rem(32);

	@include breakpoint(min-960) {
		width: rem(60);
		height: rem(60);
	}
}