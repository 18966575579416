/* ===========================
Showroom page
=========================== */
.showroom-page {
    .showroom-cover {
        text-align: center;
        padding: rem(60) 0;

        @include breakpoint(min-1280) {
            padding: rem(80) 0;
        }

        h1 {
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);
            margin: 0 auto rem(16) auto;

            @include breakpoint(min-1280) {
                max-width: rem(720);
                font-size: var(--font-size-10);
                line-height: rem(52);
                margin-bottom: rem(24);
            }
        }

        p {
            color: var(--gray-70);
            margin: 0 auto rem(40) auto;
            letter-spacing: -0.6px;
            max-width: rem(660);

            @include breakpoint(min-1280) {
                max-width: rem(720);
                font-size: var(--font-size-5);
                line-height: rem(28);
            }
        }

        .btn {
            width: 100%;
            max-width: rem(260);
            display: block;
            margin: 0 auto;
        }
    }

    .showroom-contact {
        margin: rem(16) 0 0 0;
        padding: rem(40) 0;
        background-color: var(--gray-10);

        @include breakpoint(min-480) {
            margin: rem(24) 0 0 0;
        }

        @include breakpoint(min-768) {
            margin: rem(32) 0 0 0;
            padding: rem(60) 0;
        }

        @include breakpoint(min-1024) {
            padding: rem(80) 0;
        }

        @include breakpoint(min-1280) {
            padding: 0;
            margin: rem(40) 0 rem(92) 0;
        }

        .l-showroom-section-wrapper {
            position: relative;

            @include breakpoint(min-640) {
                flex-direction: column;
            }

            @include breakpoint(min-960) {
                flex-direction: row;
            }

            @include breakpoint(min-1280) {
                align-items: flex-start;
            }
        }

        &__text {
            text-align: center;
            margin: 0 auto rem(32) auto;

            @include breakpoint(min-768) {
                max-width: rem(600);
            }

            @include breakpoint(min-960) {
                width: percent(460,960);
                margin: 0 auto 0 0;
                text-align: left;
            }

            @include breakpoint(min-1024) {
                width: percent(496,1024);
            }

            @include breakpoint(min-1280) {
                width: percent(560,1280);
                padding: rem(112) 0 rem(64) 0;
            }

            @include breakpoint(min-1600) {
                padding: rem(138) 0 rem(92) 0;
            }

            h2 {
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);
                margin-bottom: rem(16);

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                    margin-bottom: rem(24);
                }
            }

            p {
                color: var(--gray-70);
                font-size: var(--font-size-4);
                line-height: rem(28);
                letter-spacing: -0.6px;
                margin: 0 0 rem(24) 0;
                padding: 0;

                @include breakpoint(min-960) {
                    margin: 0 0 rem(40) 0;
                    font-size: var(--font-size-5);
                }

                strong {
                    color: var(--gray-90);
                    font-weight: var(--font-weight-semibold);
                }
            }

            ul {
                margin: 0 auto rem(32) auto;
                padding: 0;
                list-style-type: none;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                justify-content: center;

                @include breakpoint(min-480) {
                    text-align: left;
                }

                @include breakpoint(min-960) {
                    justify-content: left;
                    margin: 0 0 rem(40) 0;
                }

                @include breakpoint(min-1280) {
                    flex-wrap: nowrap;
                }

                li {
                    margin: 0 auto rem(32) auto;
                    display: block;
                    width: 100%;

                    &:last-of-type {
                        margin: 0;

                        p {
                            text-align: left;
                        }
                    }

                    @include breakpoint(min-480) {
                        margin: 0 rem(40) 0 0;
                        display: inline-block;
                        width: auto;

                        &:last-of-type {
                            p {
                                text-align: inherit;
                            }
                        }
                    }

                    @include breakpoint(min-768) {
                        margin: 0 rem(40) 0 0;
                    }

                    @include breakpoint(min-960) {
                        margin: 0 percent(32,960) rem(40) 0;
                    }

                    @include breakpoint(min-1024) {
                        margin: 0 percent(48,1024) 0 0;
                    }

                    @include breakpoint(min-1280) {
                        margin: 0 rem(40) 0 0;
                    }

                    @include breakpoint(min-1600) {
                        margin: 0 rem(80) 0 0;
                    }

                    h3 {
                        font-size: var(--font-size-6);
                        line-height: rem(28);
                        font-family: $font-stack-display;
                        font-weight: var(--font-weight-regular);
                        margin-bottom: rem(16);

                        @include breakpoint(min-768) {
                            font-size: var(--font-size-7);
                            line-height: rem(32);
                        }
                    }

                    p {
                        display: inline-block;
                        margin: 0;

                        strong {
                            color: var(--gray-90);
                            font-weight: var(--font-weight-semibold);
                            margin-left: rem(20);
                            display: inline-block;
                            width: 100%;

                            @include breakpoint(min-960) {
                                margin-left: percent(40,960);
                            }

                            @include breakpoint(min-1024) {
                                margin-left: percent(40,1024);
                            }

                            @include breakpoint(min-1600) {
                                margin-left: rem(40);
                            }
                        }
                    }
                }
            }

            .link-arrow {
                display: table;
                margin: 0 auto;
                color: var(--gray-90);
                box-shadow: 0 rem(1) 0 0 var(--gray-90);
                text-decoration: none;
                font-size: var(--font-size-3);
                line-height: rem(28);
                text-transform: uppercase;
                font-weight: var(--font-weight-semibold);
                position: relative;
                transition: box-shadow .2s $ease-in-out-quad;

                &:hover {
                    box-shadow: 0 rem(2) 0 0 var(--gray-90);
                }

                &:hover .icon-arrow-right {
                    transform: translateX(rem(6));
                }

                @include breakpoint(min-960) {
                    margin-left: 0;
                }
            }

            .icon-arrow-right {
                position: absolute;
                top: rem(8);
                right: rem(-16);
                display: block;
                width: rem(12);
                height: rem(12);
                transition: transform .2s $ease-in-out-quad;
            }
        }

        &__image {
            max-width: rem(480);
            width: 100%;
            order: 1;

            img {
                width: 100%;
            }

            @include breakpoint(min-768) {
                max-width: rem(720);
            }

            @include breakpoint(min-960) {
                width: percent(863,960);
            }

            @include breakpoint(min-1024) {
                width: percent(496,1024);
            }

            @include breakpoint(min-1280) {
                max-width: none;
                position: absolute;
                right: 0;
                top: rem(92);
                width: percent(680,1280);
            }

            @include breakpoint(min-1600) {
                top: rem(120);
                width: percent(863,1600);
            }
        }
    }

    .showroom-spaces {
        padding: rem(40) 0;
        text-align: center;

        @include breakpoint(min-480) {
            padding: rem(48) 0;
        }

        @include breakpoint(min-640) {
            padding: rem(60) 0;
        }

        @include breakpoint(min-960) {
            padding: rem(80) 0;
        }

        @include breakpoint(min-1280) {
            padding: rem(60) 0 rem(100) 0;
        }

        @include breakpoint(min-1600) {
            padding: rem(160) 0 rem(120) 0;
        }

        .l-showroom-section-wrapper {
            position: relative;

            @include breakpoint(min-640) {
                flex-direction: column;
            }
        }

        &__text {
            text-align: center;
            max-width: rem(593);
            margin: 0 auto;

            @include breakpoint(min-960) {
                margin: 0 auto rem(40) auto;
            }

            @include breakpoint(min-1280) {
                max-width: rem(640);
                margin: 0 auto rem(60) auto;
            }

            h2 {
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);
                margin-bottom: rem(16);

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                }

                @include breakpoint(min-1280) {
                    margin-bottom: rem(24);
                }

                small {
                    color: var(--gray-50);
                    font-family: $font-stack-text;
                    font-size: var(--font-size-3);
                    line-height: rem(14);
                    font-weight: var(--font-weight-semibold);
                    text-transform: uppercase;
                    display: block;
                }
            }
        }

        &__gallery {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        .spaces-preview {
            width: 100%;
            padding: 0;
            margin: rem(40) 0;

            figure {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                @include breakpoint(min-960) {
                    flex-wrap: nowrap;
                }
            }

            &__text {
                text-align: center;
                max-width: rem(593);
                margin: 0 auto rem(40) auto;

                @include breakpoint(min-960) {
                    margin: 0;
                    text-align: left;
                    max-width: rem(480);
                }

                @include breakpoint(min-1280) {
                    max-width: rem(526);
                }

                h3 {
                    font-size: var(--font-size-6);
                    line-height: rem(28);
                    font-family: $font-stack-display;
                    font-weight: var(--font-weight-regular);
                    margin-bottom: rem(16);

                    @include breakpoint(min-768) {
                        font-size: var(--font-size-7);
                        line-height: rem(32);
                    }
                }

                p {
                    color: var(--gray-70);
                    font-size: var(--font-size-4);
                    line-height: rem(24);
                    letter-spacing: -0.5px;
                    margin: 0;
                }
            }

            &__image {
                max-width: rem(480);
                width: 100%;
                margin: 0 auto;
                overflow: hidden;
                line-height: 0;

                @include breakpoint(min-960) {
                    margin: 0;
                }

                &:hover {
                    img {
                        transform: scale(1.02);
                    }
                }

                img {
                    width: 100%;
                    transition: transform .2s ease-in-out;
                }
            }

            &.residence {
                .spaces-preview__text {
                    @include breakpoint(min-960) {
                        order: 1;
                        margin: 0 auto 0 0;
                    }
                }

                .spaces-preview__image {
                    order: 0;

                    @include breakpoint(min-640) {
                        max-width: rem(480);
                    }

                    @include breakpoint(min-768) {
                        max-width: rem(720);
                    }

                    @include breakpoint(min-960) {
                        max-width: rem(862);
                        width: percent(540,960);
                        margin-right: rem(40);
                    }

                    @include breakpoint(min-1024) {
                        width: percent(560,1024);
                    }

                    @include breakpoint(min-1280) {
                        margin-right: rem(60);
                    }

                    @include breakpoint(min-1600) {
                        width: rem(862);
                    }
                }
            }

            &.entry {
                @include breakpoint(min-960) {
                    margin: rem(-20) 0 0 0;
                }

                .spaces-preview__text {
                    @include breakpoint(min-960) {
                        margin: 0 0 0 auto;
                    }
                }

                .spaces-preview__image {
                    max-width: rem(460);

                    @include breakpoint(min-960) {
                        margin-left: rem(40);
                    }

                    @include breakpoint(min-1280) {
                        margin-left: rem(60);
                    }
                }
            }

            &.reception {
                @include breakpoint(min-960) {
                    margin: rem(-40) 0 0 0;
                }

                .spaces-preview__text {
                    @include breakpoint(min-960) {
                        order: 1;
                        margin: 0 auto 0 0;
                    }
                }

                .spaces-preview__image {
                    order: 0;

                    @include breakpoint(min-640) {
                        max-width: rem(480);
                    }

                    @include breakpoint(min-768) {
                        max-width: rem(720);
                    }

                    @include breakpoint(min-960) {
                        max-width: rem(795);
                        width: percent(540,960);
                        margin-right: rem(40);
                    }

                    @include breakpoint(min-1024) {
                        width: percent(560,1024);
                    }

                    @include breakpoint(min-1280) {
                        margin-right: rem(60);
                    }

                    @include breakpoint(min-1600) {
                        width: rem(795);
                    }
                }
            }

            &.sample-room {
                @include breakpoint(min-960) {
                    margin: rem(160) 0 0 0;
                }

                .spaces-preview__text {
                    @include breakpoint(min-960) {
                        margin: 0 0 0 auto;
                    }
                }

                .spaces-preview__image {
                    @include breakpoint(min-640) {
                        max-width: rem(480);
                    }

                    @include breakpoint(min-768) {
                        max-width: rem(720);
                    }

                    @include breakpoint(min-960) {
                        max-width: rem(862);
                        width: percent(540,960);
                        margin-left: rem(40);
                    }

                    @include breakpoint(min-1024) {
                        width: percent(560,1024);
                    }

                    @include breakpoint(min-1280) {
                        margin-left: rem(60);
                    }

                    @include breakpoint(min-1600) {
                        width: rem(862);
                    }
                }
            }

            &.manufactory {
                @include breakpoint(min-960) {
                    margin: rem(120) 0 0 0;
                }

                figure {
                    @include breakpoint(min-960) {
                        flex-wrap: wrap;
                    }
                }

                .spaces-preview__text {
                    @include breakpoint(min-960) {
                        text-align: center;
                        max-width: rem(526);
                        margin: 0 auto rem(60) auto;
                    }

                    .link {
                        display: block;
                    }
                }

                .spaces-preview__image {
                    @include breakpoint(min-640) {
                        max-width: rem(480);
                    }

                    @include breakpoint(min-768) {
                        max-width: rem(720);
                    }

                    @include breakpoint(min-960) {
                        margin: 0 auto;
                        max-width: rem(1400);
                        width: 100%;
                    }
                }
            }
        }
    }

    //Common wrapper for section
    .l-showroom-section-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include breakpoint(min-640) {
            flex-direction: row;
        }
    }
}

.lp-jewelry-guide--showroom {

    .cover-contact {
        display: flex;
        margin-bottom: var(--space-12);
    }

    .cover-contact__wrapper {
        display: inline-flex;
        flex-direction: column;
        flex: 1 1 50%;
        gap: var(--space-1);

        .h4 {
            margin-bottom: var(--space-1);
        }
    }

    .contact-opening-hours {
        margin: var(--space-0);
        padding: var(--space-0);
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: var(--space-6);

        @include breakpoint(min-768) {
            gap: var(--space-0);
        }
    }

    .contact-opening-hours li {
        @include breakpoint(min-1600) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .contact-opening-hours li p {
        margin: var(--space-0);
        min-width: rem(200);
        font-size: var(--font-size-3);
        line-height: var( --line-height-2);

        @include breakpoint(min-768) {
            font-size: var(--font-size-4);
            line-height: var( --line-height-3);
        }
    }

    .contact-opening-hours li strong {
        font-size: var(--font-size-3);
        line-height: var( --line-height-2);

        @include breakpoint(min-768) {
            width: 150px;
            font-size: var(--font-size-4);
            line-height: var( --line-height-3);
        }
    }

    .c-advantages-section {
        margin-bottom: rem(80);

        @include breakpoint(min-768) {
            margin-bottom: rem(100);
        }

        @include breakpoint(min-1280) {
            margin-bottom: rem(160);
        }
    }
}

@import '../components/advantages-section';