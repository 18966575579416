/* ===========================
Gallery grid section
=========================== */
.c-gallery-grid-section {
    background-color: var(--white);
    padding: var(--space-8) 0;

    @include breakpoint(min-768) {
        padding: var(--space-16) 0;
    }

    @include breakpoint(min-1280) {
        padding: var(--space-20) 0;
    }
}

//page-wrap
.c-gallery-grid-section .page-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--space-8);

    @include breakpoint(min-768) {
        gap: var(--space-16);
    }
}

.c-gallery-grid-section__content {
    text-align: center;
    margin: 0 auto;
    max-width: rem(640);
}

.c-gallery-grid-section__section-heading {
    display: block;
    text-align: center;
    color: var(--gray-40);
    font-size: var(--font-size-1);
    line-height: rem(18);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0 0 var(--space-2) 0;
}

.c-gallery-grid-section__heading {
    display: block;
    text-align: center;
    font-size: var( --font-size-7);
    line-height: var( --line-height-5);
    margin-bottom: var(--space-2);
   
    @include breakpoint(min-768) {
        font-size: var(--font-size-9);
        line-height: rem(52);
        margin-bottom: var(--space-4);
    }
}

.c-gallery-grid-section__desc {
    display: block;
    text-align: center;
    color: var(--gray-70);
    font-size: var(--font-size-3);
    line-height: rem(22);
    margin: 0 0 var(--space-8) 0;

    @include breakpoint(min-768) {
        font-size: var(--font-size-5);
        line-height: var( --line-height-4);
        margin: 0 0 var(--space-10) 0;
    }

    @include breakpoint(min-1280) {
        margin: 0 0 var(--space-16) 0;
    }
}

// Category items
.c-gallery-grid-section__list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--space-2);

    @include breakpoint(min-960) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.c-gallery-grid-section__item, .c-gallery-grid-section__item a {
    display: inline-flex;
    aspect-ratio: 1 / 1;
}

.c-gallery-grid-section__item img {
    width: 100%;
    height: auto;
    display: inline-block;
}
