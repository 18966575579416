/* ===========================
Landing pages layout
=========================== */
.lp-jewelry-guide .link {
    color: var(--gray-90);
}

.lp-jewelry-guide .bold {
    font-weight: var(--font-weight-semibold);
    color: var(--gray-90);
}

//Box section
.o-page-box {
    max-width: rem(1840);
    margin: 0 rem(12) rem(24) rem(12);
    display: flex;
    flex-wrap: wrap;
    background-color: var(--gray-10);

    @include breakpoint(min-640) {
        margin: 0 rem(24) rem(40) rem(24);
    }

    @include breakpoint(min-768) {
        margin: 0 rem(40) rem(60) rem(40);
    }

    @include breakpoint(min-1280) {
        margin: 0 rem(60) rem(60) rem(60);
        min-height: rem(800);
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    @include breakpoint(min-1600) {
        margin: 0 auto rem(80) auto;
        width: calc(100% - #{rem(160)});
    }
}

.o-page-box + .o-page-section {
    margin-top: rem(80);

    @include breakpoint(min-768) {
        margin-top: rem(100);
    }

    @include breakpoint(min-1280) {
        margin-top: rem(160);
    }
}

.o-page-box + .note-info {
    text-align: center;
    font-size: var(--font-size-3);
    margin: rem(-12) auto 0 auto;

    @include breakpoint(min-640) {
        margin: rem(-24) auto 0 auto;
    }

    @include breakpoint(min-768) {
        margin: rem(-40) auto 0 auto;
    }

    @include breakpoint(min-1280) {
        font-size: var(--font-size-4);
    }
}

.o-page-box + .note-info + .o-page-section {
    margin-top: rem(80);

    @include breakpoint(min-768) {
        margin-top: rem(100);
    }

    @include breakpoint(min-1280) {
        margin-top: rem(160);
    }
}

//Cover gold theme
.o-page-box--gold {
    background-color: var(--gold-20, #F0E6DB);
}

//Cover pink theme
.o-page-box--pink {
    background-color: var(--pink-20, #F7E6E3);
}

//Simulate page-wrap
.o-page-box__content, .o-page-section__content {
    order: 1;
    flex: 0 0 92%;
    margin: rem(24) auto;
    text-align: center;

    @include breakpoint(min-640) {
        margin: rem(32) auto;
    }

    @include breakpoint(min-768) {
        text-align: left;
    }

    @include breakpoint(min-1280) {
        order: unset;
        flex: 1 1 50%;
        max-width: rem(800);
        margin: 0 0 0 4%;
        padding: rem(60) 4% rem(60) 0;
    }

    + .o-page-box__image {
        @include breakpoint(min-1280) {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

.o-page-box__content-block, .o-page-section__content-block {
    text-align: center;
    margin: 0 auto rem(40) auto;

    @include breakpoint(min-768) {
        text-align: left;
        margin: 0 0 rem(60) 0;
    }

    @include breakpoint(min-1280) {
        margin-bottom: rem(80);
    }
}

.o-page-box__content-block:last-child, .o-page-section__content-block:last-child {
    margin-bottom: 0;
}

.o-page-box__section-heading {
    display: block;
    text-align: center;
    color: var(--pink-40, #D69DA5);
    font-size: var(--font-size-2);
    line-height: rem(16);
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    margin: 0 0 rem(12) 0;
    max-width: rem(660);

    @include breakpoint(min-768) {
        font-size: var(--font-size-3);
        display: inline-flex;
        align-items: center;
        margin: 0 0 rem(20) 0;
    }

    @include breakpoint(min-1280) {
        text-align: left;
        font-size: var(--font-size-4);
        line-height: rem(20);
        margin: 0 0 rem(32) 0;
    }
}

.o-page-box__section-heading::after {
    display: none;
    content: "";
    margin-left: rem(32);
    width: rem(100);
    height: rem(1);
    background-color: var(--gray-30);

    @include breakpoint(min-768) {
        display: inline-flex;
    }

    @include breakpoint(min-1280) {
        width: rem(150);
        margin-left: rem(40);
    }
}

.o-page-box__sub-heading, .o-page-section__sub-heading {
    display: block;
    color: var(--gray-60);
    font-size: var(--font-size-1);
    line-height: rem(18);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: var(--space-2);
}

.o-page-box__heading-1, .o-page-section__heading-1 {
    display: block;
    text-align: center;
    margin-bottom: rem(24);
    max-width: rem(660);

    @include breakpoint(min-768) {
        text-align: left;
        margin-bottom: rem(32);
    }

    @include breakpoint(min-1280) {
        margin-bottom: rem(40);
    }
}

.o-page-box__heading-2, .o-page-section__heading-2 {
    display: block;
    text-align: center;
    margin-bottom: rem(12);


    @include breakpoint(min-768) {
        text-align: left;
        margin-bottom: rem(16);
    }

    @include breakpoint(min-1280) {
        margin-bottom: rem(20);
    }
}

.o-page-box__heading-3, .o-page-section__heading-3 {
    display: block;
    text-align: center;
    margin-bottom: rem(8);

    @include breakpoint(min-768) {
        text-align: left;
        margin-bottom: rem(16);
    }
}

.o-page-box__heading-4, .o-page-section__heading-4 {
    font-family: $font-stack-text;
    font-weight: var(--font-weight-semibold);
    display: block;
    text-align: center;
    font-size: var(--font-size-4);
    line-height: var(--line-height-2);
    margin-bottom: var(--space-4);

    @include breakpoint(min-768) {
        text-align: left;
        font-size: var(--font-size-5);
    }

    @include breakpoint(min-1280) {
        font-size: var(--font-size-6);
        line-height: var(--line-height-3);
        margin-bottom: var(--space-4);
    }
}

.o-page-box__desc-big, .o-page-section__desc-big {
    display: block;
    text-align: center;
    color: var(--gray-70);
    font-size: var(--font-size-4);
    line-height: rem(20);
    margin: 0 auto rem(20) auto;

    @include breakpoint(min-768) {
        text-align: left;
        font-size: var(--font-size-6);
        line-height: rem(28);
        margin: 0 0 rem(20) 0;
    }

    @include breakpoint(min-1280) {
        font-size: var(--font-size-7);
        line-height: rem(32);
        margin: 0 0 rem(32) 0;
    }
}

.o-page-box__desc-big:last-child, .o-page-section__desc-big:last-child {
    margin-bottom: 0;
}

.o-page-box__desc-big.bold, .o-page-section__desc-big.bold {
    font-weight: var(--font-weight-semibold);
    color: var(--gray-90);
}

.o-page-box__desc-medium, .o-page-section__desc-medium {
    display: block;
    text-align: center;
    color: var(--gray-70);
    font-size: var(--font-size-3);
    line-height: rem(16);
    margin: 0 auto rem(20) auto;

    @include breakpoint(min-768) {
        text-align: left;
        font-size: var(--font-size-4);
        line-height: var( --line-height-3);
        margin: 0 0 rem(20) 0;
    }

    @include breakpoint(min-1280) {
        margin: 0 0 rem(32) 0;
    }
}

.o-page-box__desc-medium:last-child, .o-page-section__desc-medium:last-child {
    margin-bottom: 0;
}

.o-page-box__desc-medium.bold, .o-page-section__desc-medium.bold {
    font-weight: var(--font-weight-semibold);
    color: var(--gray-90);
}

.o-page-box__desc-medium.display-font, .o-page-section__desc-medium.display-font {
    font-family: $font-stack-display;
    max-width: rem(620);
}

.o-page-box__desc-small, .o-page-section__desc-small {
    display: block;
    text-align: center;
    color: var(--gray-70);
    margin: 0 auto rem(16) auto;

    @include breakpoint(min-768) {
        text-align: left;
        margin: 0 0 rem(16) 0;
    }

    @include breakpoint(min-1280) {
        margin: 0 0 rem(24) 0;
    }
}

.o-page-box__desc-small:last-child, .o-page-section__desc-small:last-child {
    margin-bottom: 0;
}

.o-page-box__desc-small.bold, .o-page-section__desc-small.bold {
    font-weight: var(--font-weight-semibold);
    color: var(--gray-90);
}

.o-page-box__image, .o-page-section__image {
    margin: 0 auto;
    overflow: hidden;
    height: rem(280);

    @include breakpoint(min-640) {
        height: auto;
    }

    @include breakpoint(min-1280) {
        flex: 1 0 50%;
        height: rem(800);
        max-width: 50%;
        margin-right: auto;
    }
}

.o-page-box__comparison-list, .o-page-section__comparison-list {
    margin: 0 0 var(--space-8) 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: var(--space-8);

    @include breakpoint(min-768) {
        gap: var(--space-4);
    }

    @include breakpoint(min-1280) {
        margin: 0 0 var(--space-10) 0;
    }
}

.o-page-box__comparison-item, .o-page-section__comparison-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-2);
    color: var(--gray-70);
    font-size: var(--font-size-3);
    line-height: rem(22);

    @include breakpoint(min-768) {
       flex-direction: row;
       align-items: flex-start;
    }
}

.o-page-box__comparison-icon, .o-page-section__comparison-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    border-radius: 100%;
    width: rem(20);
    height: rem(20);
    margin: var(--space-025) 0; // Vertical align
}

.o-page-box__comparison-icon .icon, .o-page-section__comparison-icon .icon {
    width: rem(10);
    height: rem(10);
}

.o-page-box__comparison-icon.advantage, .o-page-section__comparison-icon.advantage {
    background-color: #E5F7EF;

    .icon {
        fill: #00B364;
    }
}

.o-page-box__comparison-icon.disadvantage, .o-page-section__comparison-icon.disadvantage {
    background-color: #F8E8EA;

    .icon {
        fill: #BE1E2D;
    }
}

.o-page-box__image--video, .o-page-section__image--video {
    width: 100%;
    height: auto;
    aspect-ratio: 787/560;
    border: var(--space-025) solid var(--pink-10);
    
}

.o-page-box__image--map, .o-page-section__image--map {
    width: 100%;
    height: auto;
    aspect-ratio: 760/360;

    @include breakpoint(min-1280) {
        aspect-ratio: 760/760;
    }
}

.o-page-box__image img, .o-page-section__image img {
    width: auto;
    max-width: none;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);

    @include breakpoint(min-640) {
        width: 100%;
        max-height: rem(560);
    }

    @include breakpoint(min-1280) {
        width: auto;
        max-height: none;
    }
}

.o-page-box__unordered-list {
    padding: 0;
    margin: 0 0 rem(24) 0;
    list-style-type: none;

    @include breakpoint(min-768) {
        margin-bottom: rem(32);
    }

    @include breakpoint(min-1280) {
        margin-bottom: rem(40);
    }
}

.o-page-box__unordered-list-item {
    color: var(--gray-70);
    font-size: var(--font-size-3);
    line-height: rem(20);
    margin-bottom: rem(16);
    padding-left: rem(26);
    text-align: left;

    @include breakpoint(min-1280) {
        font-size: var(--font-size-4);
        line-height: rem(24);
        margin-bottom: rem(20);
        padding-left: rem(38);
    }
}

.o-page-box__info-box--kids, .o-page-section__info-box--kids {
    width: 100%;
    border-radius: var(--border-radius-1);
    padding: var(--space-4);
    background-color: var(--pink-20);
    color: var(--pink-80);
}

.o-page-box__info-box--kids .link, .o-page-section__info-box--kids .link {
    color: var(--pink-80);
}

.o-page-box__unordered-list-item:last-child {
    margin-bottom: 0;
}

.o-page-box__unordered-list-item::before {
    position: absolute;
    left: rem(8);
    top: rem(-2);
    font-size: var(--font-size-6);
    color: var(--gray-40);
    content: "•";

    @include breakpoint(min-1280) {
        font-size: var(--font-size-7);
        left: rem(16);
    }
}

//Stripe block
.c-stripe-divider {
    display: block;
    background-color: var(--gold-40, #B29A83);
    width: rem(1);
    height: rem(60);
    margin: rem(24) auto;

    @include breakpoint(min-640) {
        height: rem(120);
        margin: rem(32) auto;
    }

    @include breakpoint(min-1280) {
        height: rem(200);
        margin: rem(40) auto;
    }
}

.o-page-section + .c-stripe-divider {
    margin-top: 0;

    @include breakpoint(min-640) {
        margin-top: rem(-8);
    }

    @include breakpoint(min-768) {
        margin-top: rem(-28);
    }

    @include breakpoint(min-1600) {
        margin-top: rem(-40);
    }
}

//Page section
.o-page-section {
    max-width: rem(1840);
    overflow: hidden;
    padding: 0 rem(12) rem(24) rem(12);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(min-640) {
        padding: 0 rem(24) rem(40) rem(24);
    }

    @include breakpoint(min-768) {
        padding: 0 rem(40) rem(60) rem(40);
    }

    @include breakpoint(min-1280) {
        padding: 0 rem(60) rem(60) rem(60);
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    @include breakpoint(min-1600) {
        padding: 0 0 rem(80) 0;
        width: calc(100% - #{rem(160)});
    }
}

.o-page-section + .o-page-section {
    padding-top: rem(80);

    @include breakpoint(min-768) {
        padding-top: rem(100);
    }

    @include breakpoint(min-1280) {
        padding-top: rem(160);
    }
}

.o-page-section__content--center {
    text-align: center;
    padding: 0;
    margin: 0 auto;
}

.o-page-section__content--center * {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.o-page-section__content--center .c-link {
    margin-top: rem(20);

    @include breakpoint(min-768) {
        margin-top: rem(32);
    }

    @include breakpoint(min-1280) {
        margin-top: rem(40);
    }
}

.o-page-section__content--center .o-page-section__desc-big + .c-link, .o-page-section__content--center .o-page-section__desc-medium + .c-link, .o-page-section__content--center .o-page-section__desc-small + .c-link {
    margin-top: 0;

    @include breakpoint(min-1280) {
        margin-top: rem(8);
    }
}

//Modification for basic style -> .o-page-box__image, .o-page-section__image style
.o-page-section__image {
    position: relative;

    @include breakpoint(min-640) {
        height: auto;
        overflow: visible;
    }
}

.o-page-section__image img {
    @include breakpoint(min-1280) {
        width: 100%;
        height: auto;
        max-height: none;
    }
}

.o-page-section__image--center {
    width: 100%;
    height: auto;
    margin: rem(80) auto 0 auto;
    text-align: center;
    flex: 1 1 auto;
    max-width: none;
    overflow: visible;
}

.o-page-section__image--center img {
    display: block;
    max-width: rem(920);
    width: auto;
    height: auto;
    position: relative;
    left: 50%;
    max-height: none;
    margin-left: 0;
    transform: translateX(-50%);

    @include breakpoint(min-1280) {
        max-width: rem(1840);
    }
}

//Link, button link
.c-link {
    display: inline-block;
    background-color: transparent;
    border: none;
    color: var(--gray-90);
    font-size: var(--font-size-3);
    line-height: rem(20);
    letter-spacing: rem(1);
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    margin: 0 auto;
    text-decoration: none;
}

.c-link:hover::after, .c-link:focus::after {
    transform: translateY(0);
}

.c-link:focus {
    outline: none;
}

.c-link::after {
    content: "";
    display: block;
    width: 100%;
    height: rem(2);
    background-color: var(--gray-90);
    transform: translateY(2px);
    transition: transform .2s ease-in-out;
}