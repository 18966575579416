/* ===========================
Promo
=========================== */

.promo-page-content {
	background: $gray-10;
}

.promo-cover {
	position: relative;

	.promo-badge {
		background: #861936;
		position: absolute;
		z-index: $z-index-5;
		top: 5%;
		left: 5%;
		color: $white;
		padding: rem(10);
		font-size: var(--font-size-3);
		line-height: rem(20);
		border-radius: rem(40);
		width: 90%;
		text-align: center;

		@include breakpoint(min-768) {
			width: auto;
			left: 10%;
			font-size: var(--font-size-4);
			padding: rem(20);
			border-radius: rem(60);
			top: 50%;
			margin-top: - rem(30);
		}
	}

	.promo-badge-invert {
		background: $white;
		position: absolute;
		z-index: $z-index-5;
		top: 5%;
		left: 5%;
		color: $white;
		padding: rem(10);
		font-size: var(--font-size-3);
		line-height: rem(20);
		border-radius: rem(40);
		width: 90%;
		text-align: center;
		color: #861936;

		@include breakpoint(min-768) {
			width: auto;
			left: 10%;
			font-size: var(--font-size-4);
			padding: rem(20);
			border-radius: rem(60);
			top: 50%;
			margin-top: - rem(30);
		}
	}
	
	&__image {
		height: rem(180);
		background: $white;
		position: relative;
		display: block;
		overflow: hidden;

		img {
			height: auto;
			min-height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: right;
		}

		@include breakpoint(min-640) {
			height: rem(300);
		}

		@include breakpoint(min-768) {
			height: rem(360);
		}
		@include breakpoint(min-768) {
			height: rem(440);
		}

		@include breakpoint(min-1280) {
			height: rem(480);
		}

		@include breakpoint(min-1600) {
			height: rem(520);
		}
	}
}

.promo-content {
	background: $white;
	padding: rem(20) 0 rem(32) 0;

	@include breakpoint(min-640) {
		padding: rem(32) 0 rem(40) 0;
	}

	@include breakpoint(min-960) {
		padding: rem(40) 0 rem(60) 0;
	}

	@include breakpoint(min-1600) {
		padding: rem(60) 0 rem(80) 0;
	}

	h1 {
		max-width: 70ch;
		text-align: center;
		margin: 0 auto rem(20) auto;
	}

	p {
		text-align: center;
		max-width: 70ch;
		margin: 0 auto rem(16) auto;
	}

	> h2 {
		max-width: 70ch;
		text-align: center;
		margin: rem(40) auto rem(12) auto;
	}

	a {
		color: $gray-90;
	}
}

.vanoce-2018-intro,
.black-friday-2018-intro,
.leden-2018-intro {
	padding: rem(32) 0 rem(44) 0;
	background-color: $white;

	@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
	@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
	@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }
	
	h1 {
		max-width: 70ch;
		text-align: center;
		margin: 0 auto rem(20) auto;
	}

	p {
		text-align: center;
		max-width: 70ch;
		margin: 0 auto rem(16) auto;

		@include breakpoint(min-960) {
			margin: 0 auto rem(24) auto;
		}
	}

	h2 {
		text-align: center;
		margin: rem(40) 0 rem(20) 0;
	}

	.black-friday-countdown,
	.christmas-countdown {
		font-size: var(--font-size-9);
		font-weight: $font-semibold;
		color: $gray-90;
		line-height: rem(80);
	}

}

.leden-2018-intro {
	padding-bottom: rem(20);
}

.vanoce-2018-advantages,
.black-friday-2018-advantages {
	padding: rem(32) 0 rem(44) 0;
	background-color: $gray-10;

	@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
	@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
	@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }

	h2 {
		max-width: 70ch;
		text-align: center;
		margin: rem(20) auto rem(40) auto;
	}
	

	&__list {
		display: flex;
		flex-direction: column;
		list-style-type: none;
		margin: 0;
		padding: 0;
		counter-reset: adv-counter;
		flex-wrap: wrap;

		@include breakpoint(min-768) {
			width: percent(640, 768);
			margin: 0 auto;
		}

		@include breakpoint(min-960) {
			width: percent(640, 960);
		}

		@include breakpoint(min-1024) {
			flex-direction: row;
			width: 100%;
		}
	}

	li {
		align-self: flex-end;
		flex: 0 0 100%;
		position: relative;
		padding-left: rem(60);
		margin-bottom: rem(24);

		@include breakpoint(min-640) {
			margin-bottom: rem(44);
		}

		@include breakpoint(min-1024) {
			align-self: flex-start;
			flex: 0 0 33.33%;
			padding: rem(72) rem(16) 0 rem(16);
		}

		@include breakpoint(min-1600) {
			padding: rem(72) rem(48) 0 rem(48);
		}

		&::before {
			content: counter(adv-counter)'.';
			counter-increment: adv-counter;
			position: absolute;
			top: 0;
			left: 0;
			color: rgba($gray-40, .5);
			font-family: $font-stack-display;
			font-size: var(--font-size-10);
			line-height: rem(60); 

			@include breakpoint(min-1024) {
				width: 100%;
				text-align: center;
			}
		}

	}


	strong {
		font-size: var(--font-size-4);
		line-height: rem(24);
		text-transform: uppercase;
		margin-bottom: rem(4);
		font-weight: $font-semibold;
		display: block;

		@include breakpoint(min-1024) {
			text-align: center;
			margin-bottom: rem(16);
		}
	}

	span {
		color: $gray-70;
		letter-spacing: -0.5;
		line-height: rem(24);
		margin: 0;
		display: block;
		text-align: left;

		@include breakpoint(min-1024){
			text-align: center;
		}
	}

}

.black-friday-2018-intro {
	padding: rem(32) 0 rem(16) 0;

	@include breakpoint(min-640) { padding: rem(44) 0 rem(32) 0; }
	@include breakpoint(min-960) { padding: rem(52) 0 rem(32) 0; }
	@include breakpoint(min-1600) { padding: rem(60) 0 rem(32) 0; }
}

.vanoce-2018-main-collection,
.leden-2018-main-collection {
	padding: rem(32) 0 rem(44) 0;
	background-color: $white;

	@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
	@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
	@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }

	.page-wrap > .btn {
		display: block;
		max-width: rem(360);
		margin: 0 auto;
	}

	h3 {
		max-width: 70ch;
		text-align: center;
		margin: rem(20) auto rem(40) auto;
	}

	p {
		text-align: center;
		max-width: 70ch;
		margin: 0 auto rem(40) auto;

		@include breakpoint(min-960) {
			margin: 0 auto rem(80) auto;
		}
	}
}

.vanoce-2018-collection,
.black-friday-2018-collection,
.leden-2018-collection {
	padding: rem(32) 0 rem(44) 0;
	background-color: $white;

	@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
	@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
	@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }

	.page-wrap > .btn {
		display: block;
		max-width: rem(360);
		margin: 0 auto rem(80) auto;
	}

	h3 {
		max-width: 70ch;
		text-align: center;
		margin: rem(20) auto rem(40) auto;
	}

	p {
		text-align: center;
		max-width: 70ch;
		margin: 0 auto rem(40) auto;
		
		@include breakpoint(min-960) {
			margin: 0 auto rem(80) auto;
		}
	}

	h4 {
		max-width: 70ch;
		text-align: center;
		margin: rem(20) auto rem(40) auto;
	}
}

.page-973 .promo-cover__image img {
	position: absolute;
	right: 0;
}

.voucher-form {
	width: 100%;
	max-width: rem(400);
	margin: rem(40) auto rem(80) auto;

	.selectbox {
		width: 100%;
		margin-bottom: rem(16);
	}

	label {
		margin-bottom: rem(32);
	}

	.btn {
		width: 100%;
	}
}

.timeframe {
	white-space: nowrap;
}

.promo-advantages {
	display: flex;
	flex-direction: column;
	list-style-type: none;
	margin: 0;
	padding: 0;
	counter-reset: adv-counter;
	flex-wrap: wrap;

	@include breakpoint(min-768) {
		width: percent(640, 768);
		margin: 0 auto;
	}

	@include breakpoint(min-960) {
		width: percent(640, 960);
	}

	@include breakpoint(min-1024) {
		flex-direction: row;
		width: 100%;
	}

	li {
		align-self: flex-end;
		flex: 0 0 100%;
		position: relative;
		padding-left: rem(60);
		margin-bottom: rem(24);

		@include breakpoint(min-640) {
			margin-bottom: rem(44);
		}

		@include breakpoint(min-1024) {
			align-self: flex-start;
			flex: 0 0 33.33%;
			padding: rem(72) rem(16) 0 rem(16);
		}

		@include breakpoint(min-1600) {
			padding: rem(72) rem(48) 0 rem(48);
		}

		&::before {
			content: counter(adv-counter)'.';
			counter-increment: adv-counter;
			position: absolute;
			top: 0;
			left: 0;
			color: rgba($gray-40, .5);
			font-family: $font-stack-display;
			font-size: var(--font-size-10);
			line-height: rem(60); 

			@include breakpoint(min-1024) {
				width: 100%;
				text-align: center;
			}
		}
	}

	strong {
		font-size: var(--font-size-4);
		line-height: rem(24);
		text-transform: uppercase;
		margin-bottom: rem(4);
		font-weight: $font-semibold;
		display: block;

		@include breakpoint(min-1024) {
			text-align: center;
			margin-bottom: rem(16);
		}
	}

	span {
		color: $gray-70;
		letter-spacing: -0.5;
		line-height: rem(24);
		margin: 0;
		display: block;
		text-align: left;

		@include breakpoint(min-1024){
			text-align: center;
		}
	}
}

.promo-advantages-container {
	padding: rem(32) 0 rem(44) 0;
	margin-top: rem(80);
	background-color: $gray-10;

	@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
	@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
	@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }
}

.promo-gift-image {
	width: 100%;
	display: block;
	margin: rem(50) auto rem(100);
	max-width: rem(800);

	img {
		max-width: 100%;
	}
}

.christmas-categories {
	background-color: $gray-10;
	padding: rem(20) 0 rem(40) 0;

	@include breakpoint(min-640) {
		padding: rem(40) 0 rem(60) 0;
	}

	@include breakpoint(min-768) {
		padding: rem(60) 0 rem(80) 0;
	}

	@include breakpoint(min-1024) {
		padding: rem(80) 0 rem(100) 0;
	}

	@include breakpoint(min-1600) {
		padding: rem(100) 0 rem(140) 0;
	}

	h2 {
		text-align: center;
		margin: 0 auto rem(28) auto;

		@include breakpoint(min-768) {
			max-width: percent(640, 768);
		}

		@include breakpoint(min-960) {
			max-width: percent(640, 960);
			
		}

		@include breakpoint(min-1024) {
			max-width: 100%;
			text-align: center;
		}
	}

	.collection-cover-subcat, .c-collection-cover-subcat {
		list-style-type: none;
		margin: rem(20) 0 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		@include breakpoint(min-640) {
			flex-direction: row;
			justify-content: center;
			margin: rem(40) 0 0;
		}

		&__item {
			background: $white;
			border-radius: rem(2);
			margin: rem(4);
			position: relative;
			padding: rem(20);
			flex: 0 0 calc(50% - rem(48));

			@include breakpoint(min-960) {
				flex: 0 0 calc(25% - rem(48));
			}
	
			&:hover {
				box-shadow: 0 rem(1) rem(20) 0 rgba(26,22,18,0.15);
			}
			
			&:last-child {
				margin: rem(4);
			}
	
		}
	
		&__item-container {
			display: block;
			height: 100%;
		}
	
		&__item-image {
			flex: 0 0 rem(100);
			margin: 0 auto rem(12) auto;
			display: block;
			max-width: 100%;
			height: auto;
		}
		
		&__item-name {
			align-self: center;
			flex: 1 1 100%;
			width: 100%;
			height: 100%;
	
			@include breakpoint(min-640) {
				text-align: center;
			}
			
			a {
				background-color: var(--gray-90);
				color: $white;
				display: block;
				font-size: var(--font-size-4);
				line-height: rem(20);
				padding: rem(12) 0;
				max-width: 80%;
				margin: rem(20) auto;
				text-align: center;
			}
	
		}
	}


}


.cover {
	
	position: relative;
	min-height: rem(180);

	@include breakpoint(min-768){ }

	@include breakpoint(min-768){}

	&__image {
		
		width: 100%;
	}

	img {
		min-height: rem(180);
		width: 100%;
		object-fit: cover;
		object-position: right;

		@include breakpoint(min-768){
			height: rem(180);
		}

		@include breakpoint(min-768){
			
			position: absolute;
		}
		
	}

	&__content {

		@include breakpoint(min-768){
			
			position: absolute;
		}
	}
}


.christmas-2020 {

	.bf-cover {
		background-color: var(--gray-90);

		.c-cover-image-box__heading {
			color: var(--white);
		}
	}

	.hp-advantages {

		.heading {
			text-align: center;
		}

		.btn {
			max-width: rem(360);
			margin: rem(40) auto 0 auto;
			display: block;
			text-decoration: none;
		}

		ul {
			flex-wrap: wrap;
		}

		li {
			flex: 0 0 50%;

			@include breakpoint(min-1600) {
				flex: 0 0 25%;
			}
		}
	}

	.heading {
		margin-bottom: rem(16);
	}

	.faq-content {
		margin: 0;
		padding: rem(32) 0;
		background-color: $gray-10;
	
		>.page-wrap {
			display: flex;
		}
	
		@include breakpoint(min-480) {
			margin: rem(12) 0 0 0;
			padding: rem(40) 0;
		}
	
		@include breakpoint(min-768) {
			margin: rem(16) 0 0 0;
			padding: rem(60) 0;
		}
	
		@include breakpoint(min-1280) {
			margin: rem(20) 0 0 0;
			padding: rem(80) 0;
		}
	
		.faq-info-list {
			margin: 0 auto;
			padding: 0;
			width: 100%;
			max-width: rem(929);
	
			&__heading {
				text-align: center;
			}
		}
	
		.faq-info-block {
			margin: 0 auto rem(32) auto;
			padding: 0;
			list-style-type: none;
			width: 100%;
	
			@include breakpoint(min-768) {
				margin: 0 auto rem(64) auto;
			}
	
			&:last-of-type {
				margin: 0 auto;
			}
	
			&__item {
				background-color: $white;
				margin: 0 0 rem(8) 0;
				padding: rem(20) rem(24);
	
				@include breakpoint(min-768) {
					padding: rem(38) rem(40);
				}
	
				&:last-of-type {
					margin: 0;
				}
	
				button {
					background-color: transparent;
					position: relative;
					display: block;
					width: 100%;
					text-align: left;
					border: none;
				}
	
				h3 {
					padding-right: rem(28);
				}
	
				.icon-chevron-bottom {
					position: absolute;
					width: rem(12);
					height: rem(12);
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
	
				p {
					letter-spacing: -0.6px;
					margin-top: rem(16);
	
					&:last-of-type {
						margin-bottom: 0;
					}
	
					@include breakpoint(min-768) {
						margin-top: rem(24);
					}
	
					strong {
						color: $gray-90;
					}
				}
	
				.images-gallery {
					display: block;
					margin-top: rem(16);
	
					@include breakpoint(min-768) {
						margin-top: rem(24);
					}
				}
			}
		}
	}

	.christmas-intro {
		padding: rem(32) 0 rem(44) 0;
		background-color: $white;
		padding-bottom: rem(20);
	
		@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
		@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
		@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }
		
		h1 {
			max-width: 70ch;
			text-align: center;
			margin: 0 auto rem(20) auto;
		}
	
		p {
			text-align: center;
			max-width: 70ch;
			margin: 0 auto rem(16) auto;
			
			@include breakpoint(min-960) {
				margin: 0 auto rem(24) auto;
			}
		}
	
		h2 {
			text-align: center;
			margin: rem(40) 0 rem(20) 0;
		}

		.btn {
			max-width: rem(360);
			margin: rem(40) auto 0 auto;
			display: block;
		}

		img {
			display: block;
			margin: rem(40) auto rem(32) auto;
		}

	}

	.christmas-products {
		padding: rem(32) 0 rem(44) 0;
		background-color: $white;
	
		@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
		@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
		@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }

		.heading {
			text-align: center;
		}
	
		.page-wrap > .btn {
			display: block;
			max-width: rem(360);
			margin: 0 auto;
		}
	
		p {
			text-align: center;
			max-width: 70ch;
			margin: 0 auto rem(40) auto;
			
			@include breakpoint(min-960) {
				margin: 0 auto rem(80) auto;
			}
		}
	}

}

.lp-products {
	padding: rem(32) 0 rem(44) 0;
	background-color: $white;

	@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
	@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
	@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }
}

.lp-products .page-wrap > h2 {
	text-align: center;
	margin-bottom: rem(16);	
}

.lp-products h3 {
	text-align: center;
	margin-bottom: rem(8);
}

.lp-products .page-wrap > .btn {
	display: block;
	max-width: rem(360);
	margin: 0 auto;
}

.lp-products p {
	text-align: center;
	max-width: 70ch;
	margin: 0 auto rem(40) auto;
	
	@include breakpoint(min-960) {
		margin: 0 auto rem(80) auto;
	}
}

.lp-products .products {
	margin-bottom: rem(20);

	@include breakpoint(min-640) {
		margin-bottom: rem(60);
	}

	@include breakpoint(min-960) {
		margin-bottom: rem(80);
	}
}




// New styles for promo pages


.l-promo-page {
	display: flex;
	flex-direction: column;
}

.c-promo-section {
	background-color: var(--gray-10);
	padding: var(--space-10) 0;	

	@include breakpoint(min-768) {
		padding: var(--space-12) 0;	
	}
	
	@include breakpoint(min-1024) {
		padding: var(--space-16) 0;	
	}

	@include breakpoint(min-1280) {
		padding: var(--space-20) 0;	
	}

	@include breakpoint(min-1600) {
		padding: var(--space-24) 0;	
	}
}

.c-promo-section p {
	max-width: 70ch;
	margin: 0 0 var(--space-10);
}

.c-promo-section--white {
	background-color: var(--white);
}

.c-promo-section,
.c-promo-section > .u-page-wrap {
	display: flex;
	flex-direction: column;
}

.c-promo-section--center,
.c-promo-section--center > .u-page-wrap {
	align-items: center;
	text-align: center;
}


// Marianne days

.c-cover-image-fullscreen--marianne .c-cover-image-fullscreen__heading {
	color: var(--pink-90);
}
.c-cover-image-fullscreen--marianne .c-cover-image-fullscreen__desc {
	font-size: var(--font-size-6);
	color: var(--pink-70);
}

.l-promo-page--marianne .collection-cover-subcat,
.l-promo-page--marianne .products {
	margin-bottom: var(--space-16);
}

.l-promo-page--marianne .collection-cover-subcat__item {
	flex: 1 1 20%;
}

.l-promo-page--marianne .collection-cover-subcat__item img {
	object-fit: contain;
}


.c-promo-category-nav {
	list-style-type: none;
	margin: 0 0 var(--space-16) 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.c-promo-category-nav__item {
	margin: var(--space-4);
}

.c-promo-category-nav__item img {
	display: block;
}

.c-promo-category-nav__item-name h3 {
	font-family: $font-stack-text;
	font-weight: var(--font-semibold);
	font-size: var(--font-size-5);
}

.c-app-download {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint(min-768) {
		flex-direction: row;
	}
}

.c-app-download__item {
	margin: var(--space-1);
}

.mastercard-gift {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	max-width: rem(800);

	@include breakpoint(min-640) {
		flex-direction: row;
	}
}

.mastercard-gift figcaption{
	padding: var(--space-10);
	font-size: var(--font-size-7);
	flex: 1 1 50%;
	display: flex;
	align-items: center;
	text-align: center;

	@include breakpoint(min-640) {
		text-align: left;
	}
	
}








.christmas-section {
	background-color: var(--white);
	text-align: center;
	padding: rem(40) 0;

	@include breakpoint(min-768) {
		padding: rem(60) 0;
	}

	@include breakpoint(min-960) {
		padding: rem(80) 0;
	}
}

.christmas-section--gray {
	background-color: var(--gray-10);
	margin: 0 0 var(--space-10) 0;

	@include breakpoint(min-768) {
		margin: 0 0 var(--space-16) 0;
	}

	@include breakpoint(min-960) {
		margin: 0 0 var(--space-20) 0;
	}
}

.christmas-section p {
	margin-bottom: rem(40);
	text-align: center;
    max-width: 70ch;
    margin: 0 auto 2.5rem auto;
}
    

.c-christmas-2022-signpost {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 100%;
	justify-content: center;
	flex-direction: column;

	@include breakpoint(min-768) {
		flex-direction: row;
	}
}

.c-christmas-2022-signpost__item {
	border-radius: rem(4);
	margin: rem(4);
	padding: rem(40) rem(16);
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;

	@include breakpoint(min-768) {
		flex: 0 0 rem(400);
		padding: rem(60) rem(32);
	}
}

.c-christmas-2022-signpost__item .icon {
	width: rem(60);
	height: rem(60);
	display: block;
	margin-bottom: rem(40);
}

.c-christmas-2022-signpost__item--female {
	background-color: var(--pink-20);
}

.c-christmas-2022-signpost__item--female p {
	color: var(--pink-90);
}

.c-christmas-2022-signpost__item--female .icon {
	fill: var(--pink-50);
}

.c-christmas-2022-signpost__item--male {
	background-color: var(--gold-20);
}

.c-christmas-2022-signpost__item--male p {
	color: var(--gold-90);
}

.c-christmas-2022-signpost__item--male .icon {
	fill: var(--gold-50);
}

.c-christmas-2022-signpost__item:hover {
	box-shadow: 0 rem(1) rem(20) 0 rgba(26,22,18,0.15);
}

.christmas-2022-benefit {
	background-color: var(--gray-10);
	text-align: center;
	padding: rem(40) 0;

	@include breakpoint(min-768) {
		padding: rem(60) 0;
	}

	@include breakpoint(min-960) {
		padding: rem(80) 0;
	}
}

.c-christmas-promo-box {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	max-width: rem(960);
	margin: 0 auto;
	background-color: var(--white);
	padding: var(--space-6) var(--space-6) var(--space-20) var(--space-6);

	@include breakpoint(min-768) {
		flex-direction: row;
		padding: var(--space-6);
	}

}

.c-christmas-promo-box--reverse {

	@include breakpoint(min-768) {
		flex-direction: row-reverse;
	}

}

.c-christmas-promo-box__image {
	width: 100%;
	max-width: 100%;
	margin-bottom: var(--space-6);

	@include breakpoint(min-768) {
		margin-right: var(--space-10);
		margin-bottom: 0;
		max-width: rem(400);
	}
	
}

.c-christmas-promo-box__image img {
	max-width: 100%;	
	height: auto;
}

.c-christmas-promo-box--reverse .c-christmas-promo-box__image {

	@include breakpoint(min-768) {
		margin-right: 0;
		margin-left: var(--space-10);
	}

}

.christmas-heading,
.christmas-paragraph {
	margin-bottom: rem(80) !important;
}

.christmas-heading span {
	margin-top: rem(8);
	display: block;
	color: var(--gray-70);
	font-weight: var(--font-weight-regular);
}

.bf-2022 h1 {
	color: var(--white);
}

// Denik promo
.lp-jewelry-guide--denik-promo .denik-app-image {
	@include breakpoint(min-1280) {
		max-width: rem(640);
		margin-left: auto;
	}
}

.sale-code-usage {
	background-color: var(--gray-05);
	padding-top: var(--space-6);

	@include breakpoint(min-640) {
		padding-top: var(--space-10);
	}

	@include breakpoint(min-1280) {
		padding-top: var(--space-16);
	}

	@include breakpoint(min-1600) {
		padding-top: var(--space-20);
	}
}

.sale-code-usage {
	margin-bottom: var(--space-20);

	@include breakpoint(min-768) {
		margin-bottom: rem(100);
	}
}

.sale-code-usage .list {
	padding: var(--space-10) 0 0 0;
	margin: 0;
	display: grid;
	gap: var(--space-6);
	grid-template-columns: repeat(1, 1fr);
	list-style-type: none;

	@include breakpoint(min-960) {
		gap: var(--space-10);
		grid-template-columns: repeat(3, 1fr);
	}

	@include breakpoint(min-1600) {
		padding-top: var(--space-20);
	}
}

.sale-code-usage .list img {
	max-width: 100%;
	border: var(--space-05) solid var(--gray-2r0);
}

.sale-code-usage .list li {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: var(--space-6);
	text-align: center;
}

.sale-code-usage .list li .o-page-section__desc-small {
	text-align: center;
}


.lp-jewelry-guide--denik-promo .o-page-section--promo {
	text-align: center;
	justify-content: center;
}