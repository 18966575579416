/* ===========================
Products
=========================== */

.l-products {
	display: flex;
	flex-direction: column;

	@include breakpoint(min-768) {
		padding: var(--space-8) 0;
	}
}

.l-products__filter {
	width: 100%;

	@include breakpoint(min-1024) {
		max-width: rem(300);
	}
}

.products {
	list-style-type: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;

	@include breakpoint(min-640) {
		flex-direction: row;
	}
}

.products__item {
	max-width: rem(380);
	margin: 0 auto rem(8) auto;

	@include breakpoint(min-640) {
		margin: rem(8);
		flex-basis: calc(50% - 1rem);
	}

	@include breakpoint(min-960) {
		flex-basis: calc(33.333333333% - 1rem);
	}

	@include breakpoint(min-1280) {
		flex-basis: calc(25% - 1rem);
	}
}

.products__promo-item {
	grid-column: auto/span 2;
	grid-row: auto/span 2;
	background-color: var(--gray-10);
	display: flex;
}

.products__promo-item figure {
	position: relative;
	width: 100%;
}

.products__promo-item figcaption {
	position: absolute;
	bottom: 0;
	padding: var(--space-8);

	@include breakpoint(min-768) {
		padding: var(--space-16);
	}
}

.products__promo-item .promo--right figcaption {
	right: 0;
}

.products__promo-item .promo--left figcaption {
	left: 0;
}

.products__promo-item .promo--bottom figcaption {
	bottom: rem(80);
}

.products__promo-item .promo--top figcaption {
	top: rem(80);
}

.products__promo-item .promo--light .h3,
.products__promo-item .promo--light p {
	color: var(--white);
}

.products__promo-item img {
	display: block;
	width: 100%;
	height: auto;
	min-height: 100%;
	object-fit: cover;
	object-position: center center;
}

@supports (display: grid) {
	.products {
		display: grid;
		grid-template-columns: repeat(2, 50%);
		grid-gap: rem(8);

		@include breakpoint(min-640) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include breakpoint(min-1280) {
			grid-template-columns: repeat(4, 1fr);
		}

		&__item {
			margin: 0;
		}

		&__item + &__item {
			margin-top: 0;
		}
	}

	.products__item {
		margin: 0;
	}

	.products__item + .products__item {
		margin-top: 0;
	}
}

.products--carousel {
	display: flex;
	scroll-snap-type: x mandatory;
	overflow-x: scroll;
	scroll-behavior: smooth;
	scroll-padding: 0 50%;
	width: auto;
	max-width: 100%;
	flex-direction: row;
	// scroll-padding-left: 212px;
	flex-wrap: nowrap;
	justify-content: flex-start;
	padding-left: 2%;
	padding-right: 2%;

	@include breakpoint(min-1600) {
		padding-left: calc(50vw - 800px);
		padding-right: calc(50vw - 800px);
	}
}

.products--center {
	@include breakpoint(min-768) {
		justify-content: center;
	}

	@include breakpoint(min-960) {
		justify-content: flex-start;
	}

	@include breakpoint(min-1600) {
		justify-content: center;
	}
}

.products--carousel .products__item {
	flex-shrink: 0;
	scroll-snap-align: start;
	width: 50%;
}

.products--carousel .c-product-card {
	scroll-snap-align: center;
	flex: 0 0 rem(162);

	@include breakpoint(min-960) {
		flex: 0 0 rem(292);
	}
}


// .l-products {
// 	display: flex;
// 	flex-direction: column;

// 	@include breakpoint(min-1024) {
// 		flex-direction: row;
// 	}
// }

// .l-products__filter {
// 	width: 100%;

// 	@include breakpoint(min-1024) {
// 		max-width: rem(300);
//     }
// }

// .l-products__content {
// 	padding: rem(8);

// 	@include breakpoint(min-768) {
//         padding: rem(16);
//     }

// 	@include breakpoint(min-1024) {
//         width: calc(100% - 260px);
//     }

// }

// .products {
// 	list-style-type: none;
// 	padding: 0;
// 	display: flex;
// 	flex-wrap: wrap;
// 	flex-direction: column;
// 	justify-content: center;
	
// 	@include breakpoint(min-640) {
// 		flex-direction: row;
// 	}
// }

// .products__item {
// 	max-width: rem(380);
// 	margin: 0 auto rem(8) auto;

// 	@include breakpoint(min-640) {
// 		margin: rem(8);
// 		flex-basis: calc(50% - 1rem);
// 	}

// 	@include breakpoint(min-960) {
// 		flex-basis: calc(33.333333333% - 1rem);
// 	}

// 	@include breakpoint(min-1280) {
// 		flex-basis: calc(25% - 1rem);
// 	}

// }

// .products__promo-item {
// 	grid-column: auto/span 2; 
// 	grid-row: auto/span 2; 
// 	background-color: var(--gray-10);
// 	display: flex;
// }

// .products__promo-item figure {
// 	position: relative;
// }

// .products__promo-item figcaption {
// 	position: absolute;
// 	bottom: 0;
// 	padding: var(--space-8);

// 	@include breakpoint(min-768) {
// 		padding: var(--space-16);
// 	}
	
// }

// .products__promo-item .promo--right figcaption {
// 	right: 0;
// }

// .products__promo-item .promo--left figcaption {
// 	left: 0;
// }

// .products__promo-item .promo--bottom figcaption {
// 	bottom: rem(80);
// }

// .products__promo-item .promo--top figcaption {
// 	top: rem(80);
// }

// .products__promo-item img {
// 	display: block;
// 	max-width: 100%;
// 	height: 100%;
// 	object-fit: cover;
// 	object-position: right bottom;
// }

// .products__promo-item .christmas-2022 figcaption {
// 	top: 15%;
// }


// .products__promo-item .marianne-days .h3 {
// 	color: var(--pink-90);
// }

// .products__promo-item .marianne-days p {
// 	color: var(--pink-70);
// }

// .products__promo-item .shopping-with-mother .h3 {
// 	color: var(--white);
// 	text-shadow: 0 var(--space-05) var(--space-2) var(--gray-70);
// }

// @supports (display: grid) {
// 	.products {
// 		display: grid;
// 		grid-template-columns: repeat(2, 50%);
// 		grid-gap: rem(8);

// 		@include breakpoint(min-640) {
// 			grid-template-columns: repeat(3, 1fr);
// 		}

// 		@include breakpoint(min-1280) {
// 			grid-template-columns: repeat(4, 1fr);
// 		}
		
// 		&__item {
// 			margin: 0;
// 		}
		
// 		&__item + &__item {
// 			margin-top: 0;
// 		}
// 	}

// 	.products__item {
// 		margin: 0;
// 	}

// 	.products__item + .products__item {
// 		margin-top: 0;
// 	}
// }

// .products--carousel {
// 	display: flex;
// 	scroll-snap-type: x mandatory;
// 	overflow-x: scroll;
// 	scroll-behavior: smooth;
// 	scroll-padding: 0 50%;
// 	width: auto;
// 	max-width: 100%;
// 	flex-direction: row;
// 	// scroll-padding-left: 212px;
// 	flex-wrap: nowrap;
// 	justify-content: flex-start;
// 	padding-left: 2%;
// 	padding-right: 2%;

// 	@include breakpoint(min-1600) {
// 		padding-left: calc(50vw - 800px);
//     	padding-right: calc(50vw - 800px);
// 	}
// }

// .products--center {
// 	@include breakpoint(min-768) {
// 		justify-content: center;
// 	}

// 	@include breakpoint(min-960) {
// 		justify-content: flex-start;
// 	}

// 	@include breakpoint(min-1600) {
// 		justify-content: center;
// 	}
// }

// .products--carousel .products__item {
// 	flex-shrink: 0;
// 	scroll-snap-align: start;
// 	width: 50%;
// }

// .products--carousel .c-product-card {
// 	scroll-snap-align: center;
// 	flex: 0 0 rem(162);
	
// 	@include breakpoint(min-960) {
// 		flex: 0 0 rem(292);
// 	}
// }