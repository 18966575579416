/* ===========================
Cover image (box) - component
=========================== */
.c-cover-image-box {
    max-width: rem(1840);
    margin: 0 rem(12) rem(24) rem(12);
    display: flex;
    flex-wrap: wrap;
    background-color: $gray-10;

    @include breakpoint(min-640) {
        margin: 0 rem(24) rem(40) rem(24);
    }

    @include breakpoint(min-768) {
        margin: 0 rem(40) rem(60) rem(40);
    }

    @include breakpoint(min-1280) {
        margin: 0 rem(60) rem(60) rem(60);
        min-height: rem(800);
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    @include breakpoint(min-1600) {
        margin: 0 auto rem(80) auto;
        width: calc(100% - #{rem(160)});
    }
}

.c-cover-image-box .strong {
    font-weight: $font-semibold;
    color: $gray-90;
}

.c-cover-image-box .link {
    font-weight: $font-semibold;
    color: $gray-90;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

//Cover gold theme
.c-cover-image-box--gold {
    background-color: $gold-20;
}

.c-cover-image-box--gold .c-cover-image-box__section-heading {
    color: $gold-40;
}

.c-cover-image-box--gold .c-cover-image-box__section-heading::after {
    background-color: $gold-30;
}

//Cover pink theme
.c-cover-image-box--pink {
    background-color: $pink-20;
}

.c-cover-image-box--pink .c-cover-image-box__section-heading {
    color: $pink-40;
}

.c-cover-image-box--pink .c-cover-image-box__section-heading::after {
    background-color: $pink-30;
}


.c-cover-image-box--pink .c-cover-image-box__button--primary {
    background-color: $pink-70;
    border-radius: rem(6);
}

.c-cover-image-box--pink .c-cover-image-box__button--primary:hover {
    background-color: $pink-80;
    box-shadow: 0 0 0 2px $pink-80;
}

.c-cover-image-box--pink .c-cover-image-box__button--primary-inverted {
    border-color: $pink-70;
    color: $pink-70;
    border-radius: rem(6);
}

.c-cover-image-box--pink .c-cover-image-box__button--primary-inverted:hover {
    color: $white;
    background-color: $pink-80;
    border-color: $pink-80;
    box-shadow: 0 0 0 2px $pink-80;
}



// Cover custom 1 theme
.c-cover-image-box--custom-1 {
    background-color: #B4AEA8;
}

.c-cover-image-box--custom-1 .c-cover-image-box__heading,
.c-cover-image-box--custom-1 .c-cover-image-box__desc {
    color: #fff;
}

.c-cover-image-box--custom-1 .c-cover-image-box__button--primary {
    background-color: #fff;
    color: var(--gray-90);
    border-radius: rem(6);
}



// Cover custom 2 theme
.c-cover-image-box--custom-2 {
    background-color: #103932;
}

.c-cover-image-box--custom-2 .c-cover-image-box__heading,
.c-cover-image-box--custom-2 .c-cover-image-box__desc {
    color: #fff;
}

.c-cover-image-box--custom-2 .c-cover-image-box__button--primary {
    background-color: #353433;
    border-radius: rem(6);
}

// Cover custom 3 theme
.c-cover-image-box--custom-3 {
    background-color: #103932;
}

.c-cover-image-box--custom-3 .c-cover-image-box__heading,
.c-cover-image-box--custom-3 .c-cover-image-box__desc {
    color: #fff;
}

.c-cover-image-box--custom-3 .c-cover-image-box__button--primary {
    background-color: #fff;
    color: var(--gray-90);
    border-radius: rem(6);
}
// Cover custom 4 theme
.c-cover-image-box--custom-4 {
    background-color: #984857;
}

.c-cover-image-box--custom-4 .c-cover-image-box__heading,
.c-cover-image-box--custom-4 .c-cover-image-box__desc {
    color: #fff;
}

.c-cover-image-box--custom-4 .c-cover-image-box__button--primary {
    background-color: var(--white);
    border-radius: rem(6);
    color: var(--gray-90);
}

// Cover custom 5 theme
.c-cover-image-box--custom-5 {
    background-color: #A8A199;
}

.c-cover-image-box--custom-5 .c-cover-image-box__heading,
.c-cover-image-box--custom-5 .c-cover-image-box__desc {
    color: #fff;
}

.c-cover-image-box--custom-5 .c-cover-image-box__button--primary {
    background-color: var(--white);
    border-radius: rem(6);
    color: var(--gray-90);
}

// Cover custom 6 theme
.c-cover-image-box--custom-6 {
    background-color: #DDDDDB;
}

.c-cover-image-box--custom-6 .c-cover-image-box__heading {
    color: var(--gray-90);
}

.c-cover-image-box--custom-6 .c-cover-image-box__desc {
    color: var(--gray-70);
}

.c-cover-image-box--custom-6 .c-cover-image-box__button--primary {
    background-color: #fff;
    border-radius: rem(6);
    color: var(--gray-90);
    border: transparent;
}


// Cover custom bf theme
.c-cover-image-box--custom-bf {
    background-color: #123932;
}

.c-cover-image-box--custom-bf .c-cover-image-box__heading,
.c-cover-image-box--custom-bf .c-cover-image-box__desc {
    color: #fff;
}

.c-cover-image-box--custom-bf .c-cover-image-box__button--primary {
    background-color: #fff;
    color: var(--gray-90);
    border-radius: rem(6);
}



//Simulate page-wrap
.c-cover-image-box__content {
    order: 1;
    flex: 0 0 92%;
    margin: rem(24) auto;
    text-align: center;

    @include breakpoint(min-640) {
        margin: rem(32) auto;
    }

    @include breakpoint(min-768) {
        text-align: left;
    }

    @include breakpoint(min-1280) {
        order: unset;
        flex: 1 1 50%;
        max-width: rem(800);
        margin: 0 0 0 4%;
        padding: rem(60) 4% rem(60) 0;
    }

    + .c-cover-image-box__image, + .c-cover-image-box__image {
        @include breakpoint(min-1280) {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

//Content items
.c-cover-image-box__section-heading {
    display: block;
    text-align: center;
    color: $gray-50;
    font-size: var(--font-size-2);
    line-height: rem(16);
    font-weight: $font-semibold;
    text-transform: uppercase;
    margin: 0 0 rem(12) 0;
    max-width: rem(660);

    @include breakpoint(min-768) {
        font-size: var(--font-size-3);
        display: inline-flex;
        align-items: center;
        margin: 0 0 rem(16) 0;
    }

    @include breakpoint(min-1280) {
        text-align: left;
        font-size: var(--font-size-4);
        line-height: rem(20);
    }
}

.c-cover-image-box__section-heading::after {
    display: none;
    content: "";
    margin-left: rem(32);
    width: rem(100);
    height: rem(1);
    background-color: $gray-30;

    @include breakpoint(min-768) {
        display: inline-flex;
    }

    @include breakpoint(min-1280) {
        width: rem(150);
        margin-left: rem(40);
    }
}

.c-cover-image-box__heading {
    display: block;
    text-align: center;
    max-width: rem(660);

    @include breakpoint(min-768) {
        text-align: left;
    }
}

.c-cover-image-box__desc {
    display: block;
    text-align: center;
    color: $gray-70;
    font-size: var(--font-size-4);
    line-height: rem(20);
    margin: 0 auto rem(32) auto;

    @include breakpoint(min-768) {
        text-align: left;
        font-size: var(--font-size-5);
        line-height: rem(28);
        margin: 0 0 rem(40) 0;
    }
}

.c-cover-image-box__desc:last-child {
    margin-bottom: 0;
}

//Buttons & link wrapper
.c-cover-image-box__wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 1 1 100%;
    margin: rem(-4);

    @include breakpoint(min-768) {
        justify-content: flex-start;
    }
}

//Items alignment in wrapper
.c-cover-image-box__wrapper * {
    margin: rem(4);
}

.c-cover-image-box__wrapper .c-cover-image-box__button:not(:only-child) {
   width: 100%;
    max-width: rem(360);

    @include breakpoint(min-768) {
       width: auto;
    }
}

.c-cover-image-box__wrapper .c-cover-image-box__button:last-of-type {
    @include breakpoint(min-768) {
        margin-right: rem(32);
    }
}

.c-cover-image-box__wrapper .c-link {
    width: auto;
    margin-top: rem(24);
    margin-left: 0;
    margin-right: 0;

    @include breakpoint(min-768) {
        margin-top: rem(16);
        margin-bottom: rem(16);
    }
}

//Buttons
.c-cover-image-box__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-3);
    line-height: rem(20);
    font-weight: $font-semibold;
    text-transform: uppercase;
    padding: rem(8) rem(24);
    height: rem(48);
    text-decoration: none;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, box-shadow .2s ease-in-out;

    @include breakpoint(min-768) {
        font-size: var(--font-size-4);
        padding: rem(8) rem(44);
        height: rem(56);
    }
}

.c-cover-image-box__button + .link-gap {
    @include breakpoint(min-768) {
        display: inline-flex;
    }
}

.c-cover-image-box__button--primary {
    color: $white;
    background-color: $gray-90;
}

.c-cover-image-box__button--primary:hover {
    box-shadow: 0 0 0 2px $gray-90;
}

.c-cover-image-box__button--primary-inverted {
    color: $gray-90;
    background-color: $white;
    border: rem(2) solid $gray-90;
}

.c-cover-image-box__button--primary-inverted:hover {
    color: $white;
    background-color: $gray-90;
    box-shadow: 0 0 0 2px $gray-90;
}

.link-gap {
    width: rem(24);
    display: none;
}

.c-cover-image-box .c-cover-image-box__button+.link-gap {
    @include breakpoint(min-1280) {
        width: 100%;
    }
}

//Image
.c-cover-image-box__image {
    margin: 0 auto;
    overflow: hidden;
    height: rem(280);

    @include breakpoint(min-640) {
        height: auto;
    }

    @include breakpoint(min-1280) {
        flex: 1 0 50%;
        height: rem(800);
        max-width: 50%;
        margin-right: auto;
    }
}

.c-cover-image-box__image img {
    width: auto;
    max-width: none;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);

    @include breakpoint(min-640) {
        width: 100%;
        max-height: rem(560);
    }

    @include breakpoint(min-1280) {
        width: auto;
        max-height: none;
    }
}