/* ===========================
Page / Search
=========================== */

.search-content-container {
    display: flex;
    flex-direction: column;
    padding: rem(20) 0 rem(32);

    @include breakpoint(min-640) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    @include breakpoint(min-960) {
        padding: rem(60) 0 rem(80);
    }

    h1 {
        flex: 0 0 100%;
        text-align: center;
        margin-bottom: rem(20);

        @include breakpoint(min-960) {
            margin-bottom: rem(40);
        }

        strong {
            display: block;
        }
    }

    .results-products,
    .results-categories {

        @include breakpoint(min-640) {
            flex: 0 0 100%;
        }

        @include breakpoint(min-640) {
            margin: 0 rem(16);
        }

        th {
            text-align: left;
            background-color: $gray-10;
            padding: rem(8);
        }

        td {
            padding: rem(8);
        }

    }
    
    .results-products {

        margin-bottom: rem(20);
        
        @include breakpoint(min-960) {
            flex: 0 0 calc(70% - 2rem);
            margin-bottom: 0;
        }
    }

    .results-categories {
        @include breakpoint(min-960) {
            flex: 0 0 calc(30% - 2rem);
        }
    }
}
