/* ===========================
FAQ page
=========================== */
.faq-page {
    .faq-cover {
        text-align: center;
        padding: rem(20) 0 rem(60) 0;

        @include breakpoint(min-1280) {
            padding: rem(40) 0 rem(80) 0;
        }

        h1 {
            margin: 0 auto rem(16) auto;

            @include breakpoint(min-1280) {
                max-width: rem(720);
            }
        }

        p {
            margin: 0 auto;
            max-width: rem(660);

            @include breakpoint(min-1280) {
                max-width: rem(720);
            }
        }
    }

    .faq-content {
        margin: 0;
        padding: rem(32) 0;
        background-color: $gray-10;

        >.page-wrap {
            display: flex;
        }

        @include breakpoint(min-480) {
            margin: rem(12) 0 0 0;
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            margin: rem(16) 0 0 0;
            padding: rem(60) 0;
        }

        @include breakpoint(min-1280) {
            margin: rem(20) 0 0 0;
            padding: rem(80) 0;
        }

        .faq-info-list {
            margin: 0 auto;
            padding: 0;
            width: 100%;
            max-width: rem(929);

            @include breakpoint(min-960) {
                margin: 0 0 0 percent(76,1600);
            }

            @include breakpoint(min-1600) {
                margin: 0 percent(335,1600) 0 percent(76,1600);
            }

        }

        .faq-info-block {
            margin: 0 auto rem(32) auto;
            padding: 0;
            list-style-type: none;
            width: 100%;

            @include breakpoint(min-768) {
                margin: 0 auto rem(64) auto;
            }

            &:last-of-type {
                margin: 0 auto;
            }

            &__item {
                background-color: $white;
                margin: 0 0 rem(8) 0;
                padding: rem(20) rem(24);

                @include breakpoint(min-768) {
                    padding: rem(38) rem(40);
                }

                &:last-of-type {
                    margin: 0;
                }

                button {
                    background-color: transparent;
                    position: relative;
                    display: block;
                    width: 100%;
                    text-align: left;
                    border: none;
                }

                h3 {
                    padding-right: rem(28);
                    margin: 0;
                }

                .icon-chevron-bottom {
                    position: absolute;
                    width: rem(12);
                    height: rem(12);
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                p {
                    letter-spacing: -0.6px;
                    margin-top: rem(16);

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    @include breakpoint(min-768) {
                        margin-top: rem(24);
                    }

                    strong {
                        color: $gray-90;
                    }
                }

                .images-gallery {
                    display: block;
                    margin-top: rem(16);

                    @include breakpoint(min-768) {
                        margin-top: rem(24);
                    }
                }
            }
        }
    }

    //Left menu
    .aside-menu-container {
        margin-bottom: rem(40);
        flex: 0 0 auto;
        display: none;

        @include breakpoint(min-960) {
            display: block;
        }

        @include breakpoint(min-1280) {
            margin-bottom: 0;
            width: rem(260);
        }

        &__block-list {
            margin: 0;
            padding: 0 0 0 rem(20);
            list-style-type: none;

            li {
                padding-left: rem(24);
                display: flex;
                align-items: center;
                margin-bottom: rem(12);

                &:last-of-type {
                    margin-bottom: 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    background-color: $gray-40;
                    width: rem(8);
                    height: rem(2);
                    transform: translateY(-50%);
                }
            }

            a {
                color: $gray-90;
                text-decoration: none;
                font-size: var(--font-size-4);
                line-height: rem(24);
                position: relative;
                transition: box-shadow .2s $ease-in-out-quad;

                &:hover {
                    box-shadow: 0 rem(2) 0 0 $gray-90;

                    .icon-arrow-right {
                        opacity: 1;
                    }
                }
            }

            .icon-arrow-right {
                position: absolute;
                top: rem(10);
                right: - rem(24);
                display: block;
                width: rem(12);
                height: rem(12);
                opacity: 0;
                transition: opacity .2s $ease-in-out-quad;
            }
        }
    }

    .l-aside-block-wrapper {
        margin: 0 0 rem(36) 0;

        &:last-of-type {
            margin: 0;
        }
    }
}