/* ===========================
Cover image (fullscreen) - component
=========================== */
.c-cover-image-fullscreen {
    overflow: hidden;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    background-color: white;

    @include breakpoint(min-768) {
        min-height: rem(480);
    }

    @include breakpoint(min-1280) {
        min-height: rem(600);
    }
}

.c-cover-image-fullscreen picture {
    width: 100%;
    height: auto;

    @include breakpoint(min-768) {
        position: absolute;
        height: 100%;
    }
}

//Size height - small
.c-cover-image-fullscreen--small {
    @include breakpoint(min-960) {
        min-height: 0;
    }

    @include breakpoint(min-1280) {
        min-height: 0;
    }
}

.c-cover-image-fullscreen--small .c-cover-image-fullscreen__content {
    padding: 0;

    @include breakpoint(min-768) {
        padding: 0;
    }

    @include breakpoint(min-1280) {
        padding: 0;
    }
}

//Size height - big
.c-cover-image-fullscreen--big {

    @include breakpoint(min-768) {
        height: calc(100vh - #{rem(76)});
        max-height: rem(720);
    }

    @include breakpoint(min-960) {
        height: calc(100vh - #{rem(140)});
    }

    @include breakpoint(min-1280) {
        max-height: rem(920);
    }
}

//Fullscreen colorTheme - light
.c-cover-image-fullscreen--light {
    background-color: var(--gray-05);

}

.c-cover-image-fullscreen--light .c-cover-image-fullscreen__section-heading, .c-cover-image-fullscreen--light .c-cover-image-fullscreen__heading, .c-cover-image-fullscreen--light .c-cover-image-fullscreen__desc, .c-cover-image-fullscreen--light .breadcrumbs-wrapper .breadcrumbs__item, .c-cover-image-fullscreen--light .breadcrumbs-wrapper .breadcrumbs__link {
    @include breakpoint(min-768) {
        color: var(--white);
     }
}

.c-cover-image-fullscreen--light .c-cover-image-fullscreen__section-heading::after {
    @include breakpoint(min-768) {
        background-color: var(--white);
     }
}

// Custom styles for breadcrumbs - TODO: NEED TO UNIFY
.c-cover-image-fullscreen .breadcrumbs-wrapper {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: rem(60);
}

.c-cover-image-fullscreen .breadcrumbs-wrapper .page-wrap {
    justify-content: center;

    @include breakpoint(min-768) {
        justify-content: flex-start;
     }
}

.c-cover-image-fullscreen .breadcrumbs-wrapper .page-wrap .breadcrumbs, .c-cover-image-fullscreen .breadcrumbs-wrapper .page-wrap .breadcrumbs__item {
    display: inline;
}

//Fullscreen variant - center
.c-cover-image-fullscreen--center
.c-cover-image-fullscreen__content {
    text-align: center;
    max-width: rem(960);
    width: 100%;

    @include breakpoint(min-768) {
        margin: 0 auto;
    }
}

.c-cover-image-fullscreen--center .c-cover-image-fullscreen__section-heading::after {
    display: none;
}

.c-cover-image-fullscreen--center .c-cover-image-fullscreen__heading {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.c-cover-image-fullscreen--center .c-cover-image-fullscreen__desc {
    text-align: center;
    max-width: rem(800);

    @include breakpoint(min-768) {
       margin-left: auto;
       margin-right: auto;
    }
}

.c-cover-image-fullscreen--center .c-cover-image-fullscreen__wrapper {
   justify-content: center;
}

.c-cover-image-fullscreen--center .c-cover-image-fullscreen__button+.link-gap {
    width: 100%;
}

//Fullscreen variant - right
.c-cover-image-fullscreen--right .c-cover-image-fullscreen__content {
    margin-left: auto;
}

//page-wrap
.c-cover-image-fullscreen .page-wrap {
    display: inline-flex;
    align-items: center;
    z-index: 1;
}

.c-cover-image-fullscreen .strong {
    font-weight: $font-semibold;
    color: $gray-90;
}

.c-cover-image-fullscreen .link {
    font-weight: $font-semibold;
    color: $gray-90;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

//Image
.c-cover-image-fullscreen img {
    display: inline-block;
    width: 100%;
    height: auto;

    @include breakpoint(min-768) {
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include breakpoint(min-1280) {
        left: auto;
        right: 0;
        transform: translate(0, -50%);
    }
}

//Simulate page-wrap
.c-cover-image-fullscreen__content {
    padding: rem(32) 0;
    margin: 0 auto;

    @include breakpoint(min-768) {
        padding: rem(60) 0;
        margin: 0;
    }

    @include breakpoint(min-1280) {
        width: 50%;
        padding: rem(80) 0;
    }
}

//Content items
.c-cover-image-fullscreen__section-heading {
    display: block;
    text-align: center;
    color: $gray-50;
    font-size: var(--font-size-2);
    line-height: rem(16);
    font-weight: $font-semibold;
    text-transform: uppercase;
    margin: 0 0 rem(12) 0;
    max-width: rem(660);

    @include breakpoint(min-768) {
        font-size: var(--font-size-3);
        display: inline-flex;
        align-items: center;
        margin: 0 0 rem(16) 0;
    }

    @include breakpoint(min-1280) {
        text-align: left;
        font-size: var(--font-size-4);
        line-height: rem(20);
    }
}

.c-cover-image-fullscreen__section-heading::after {
    display: none;
    content: "";
    margin-left: rem(32);
    width: rem(100);
    height: rem(1);
    background-color: $gray-30;

    @include breakpoint(min-768) {
        display: inline-flex;
    }

    @include breakpoint(min-1280) {
        width: rem(150);
        margin-left: rem(40);
    }
}

.c-cover-image-fullscreen__heading {
    display: block;
    text-align: center;
    margin-bottom: rem(20);
    max-width: rem(660);

    @include breakpoint(min-768) {
        text-align: left;
    }

}

.c-cover-image-fullscreen__desc {
    display: block;
    text-align: center;
    color: $gray-70;
    font-size: var(--font-size-4);
    line-height: rem(20);
    margin: 0 auto rem(32) auto;
    max-width: rem(660);

    @include breakpoint(min-768) {
        text-align: left;
        font-size: var(--font-size-5);
        line-height: rem(28);
        margin: 0 0 rem(40) 0;
    }
}

.c-cover-image-fullscreen__desc:last-child {
    margin-bottom: 0;
}

//Buttons & link wrapper
.c-cover-image-fullscreen__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 100%;
    margin: rem(-4);

    @include breakpoint(min-768) {
        flex-direction: unset;
        display: inline-flex;
        justify-content: flex-start;
    }
}

//Items alignment in wrapper
.c-cover-image-fullscreen__wrapper * {
    margin: rem(4);
}

.c-cover-image-fullscreen__wrapper .c-cover-image-fullscreen__button:not(:only-child) {
    width: 100%;
    max-width: rem(360);

    @include breakpoint(min-768) {
        width: auto;
    }
}

.c-cover-image-fullscreen__wrapper .c-cover-image-fullscreen__button:last-of-type {
    @include breakpoint(min-768) {
        margin-right: rem(32);
    }
}

.c-cover-image-fullscreen__wrapper .c-link {
    width: auto;
    margin-top: rem(24);
    margin-left: 0;
    margin-right: 0;

    @include breakpoint(min-768) {
        margin-top: rem(16);
        margin-bottom: rem(16);
    }
}

//Buttons
.c-cover-image-fullscreen__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-3);
    line-height: rem(20);
    font-weight: $font-semibold;
    text-transform: uppercase;
    padding: rem(8) rem(24);
    height: rem(48);
    text-decoration: none;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, box-shadow .2s ease-in-out;

    @include breakpoint(min-768) {
        font-size: var(--font-size-4);
        padding: rem(8) rem(44);
        height: rem(56);
    }
}

.c-cover-image-fullscreen__button + .link-gap {
    @include breakpoint(min-768) {
        display: inline-flex;
    }
}

.c-cover-image-fullscreen__button--primary {
    color: $white;
    background-color: $gray-90;
}

.c-cover-image-fullscreen__button--primary:hover {
    box-shadow: 0 0 0 2px $gray-90;
}

.c-cover-image-fullscreen__button--primary-inverted {
    color: $gray-90;
    background-color: $white;
    border: rem(2) solid $gray-90;
}

.c-cover-image-fullscreen__button--primary-inverted:hover {
    color: $white;
    background-color: $gray-90;
    box-shadow: 0 0 0 2px $gray-90;
}

.link-gap {
    width: rem(24);
    display: none;
}